import { useHistory } from "react-router-dom";
import {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import FormLabel from "./FormLabel";
import {MembershipTier} from "../../constants";

const MembershipType = ({ schoolTypes, formData, handleFormData, handleFileUpload, active = true, instruments, registration }) => {
  const navigate = useHistory();
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(window.location.href.includes('red') ? MembershipTier.RED : MembershipTier.BLACK);
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === MembershipTier.RED) {
      navigate.push(`/${i18n.language}/registration-red`);
    }
    else if (value === MembershipTier.BLACK) {
      navigate.push(`/${i18n.language}/registration-free`);
    }
  };

  return (
    <div id="formPart3" className={`formSteps step3 col-12 flex-wrap ${active ? 'curStep' : ''}`}>
      <div className="regBoxes d-flex">
        {
            registration &&
            registration[0] &&
            registration[0].content && (
                <a className="regBox"
                   onClick={() => navigate.push(`/${i18n.language}/registration-free`)}
                   dangerouslySetInnerHTML={{
                     __html:
                         i18n.language === 'tc'
                             ? registration[0].content['tc']
                             : i18n.language === 'sc'
                                 ? registration[0].content['sc']
                                 : registration[0].content['en'],
                   }}
                >
                </a>
            )}

        {
            registration &&
            registration[1] &&
            registration[1].content && (
                <a
                    className="regBox"
                    target="_blank"
                    onClick={() => navigate.push(`/${i18n.language}/registration-red`)}
                    dangerouslySetInnerHTML={{
                      __html:
                          i18n.language === 'tc'
                              ? registration[1].content['tc']
                              : i18n.language === 'sc'
                                  ? registration[1].content['sc']
                                  : registration[1].content['en'],
                    }}
                ></a>
            )
        }
      </div>
      <div className="formCol col-12 d-flex">
  <label className="flex-fill">
    <input
        className="form-check-input"
        type="radio"
        value={MembershipTier.BLACK}
        checked={selectedOption === MembershipTier.BLACK}
        onChange={handleOptionChange}
        style={{ marginRight: '5px' }}
    />
    Register Club Bravo {MembershipTier.BLACK} (Free)
  </label>
  <label className="flex-fill">
    <input
        className="form-check-input"
        type="radio"
        value={MembershipTier.RED}
        checked={selectedOption === MembershipTier.RED}
        onChange={handleOptionChange}
        style={{ marginRight: '5px' }}
    />
    Register Club Bravo {MembershipTier.RED} (+$300)
  </label>
</div>
      </div>
  );
};

const mapStateToProps = state => ({
  instruments: state.shared.instruments,
  schoolTypes: state.shared.school_types,
  registration: state.shared.registration,

});

export default connect(mapStateToProps)(MembershipType);
