import { makeApiCalls } from "../store/api";
import axios, { AxiosResponse } from "axios";
import { getI18n } from "react-i18next";

const KEY_JWT = 'jwt-token';
const KEY_PROFILE = 'me';

// jwt token
export function setJwtToken(token: string) {
    localStorage.setItem(KEY_JWT, token);
}

export function getJwtToken() {
    return localStorage.getItem(KEY_JWT);
}

export function removeJwtToken() {
    localStorage.removeItem(KEY_JWT);
}

export function setUserProfile(user: any) {
    return localStorage.setItem(KEY_PROFILE, JSON.stringify(user));
}

export function removeMe() {
    localStorage.removeItem(KEY_PROFILE);
}

export function isUserExist(
    email: string,
    password: string,
) {
    return makeApiCalls(
        [
            {
                endpoint: 'FIND_OCTOBER_PROFILE',
                data: {
                    "username": email,
                    "password": password,
                }
            }
        ],
    ).then((responses: AxiosResponse[]) => {
        return responses[0]?.data;
    });
}

export function register(email, password) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_REGISTER',
                data: {
                    "email": email,
                    "password": password,
                    "password_confirmation": password
                }
            }
        ],
    ).then((responses: AxiosResponse[]) => {
        return responses[0]?.statusText;
    })
}

export function getSCTier({ sc_token }) {
    return makeApiCalls(
        [
            {
                endpoint: 'GET_SC_TIER',
                data: {
                    sc_token,
                }
            }
        ],
    ).then((responses: AxiosResponse[]) => {
        return responses[0]?.data;
    })
}

export function getFreeUpgradeTier({ free_tier_upgrade_token }) {
    return makeApiCalls(
        [
            {
                endpoint: 'GET_FREE_UPGRADE_TIER',
                data: {
                    free_tier_upgrade_token,
                }
            }
        ],
    ).then((responses: AxiosResponse[]) => {
        return responses[0]?.data;
    })
}

export function getExistingMemberProfile({ cb_member_no, last_name, email_or_mobile }) {
    return makeApiCalls(
        [
            {
                endpoint: 'GET_EXISTING_MEMBER_PROFILE',
                data: {
                    cb_member_no,
                    last_name,
                    email_or_mobile,
                }
            }
        ],
    ).then((responses: AxiosResponse[]) => {
        return responses[0]?.data;
    })
}

export function verifyEmailOrMobile(
    email_or_mobile: string
) {
    return makeApiCalls(
        [
            {
                endpoint: 'PUBLIC_VERIFY_EMAIL_OR_MOBILE',
                params: { email_or_mobile }
            }
        ],
    ).then((responses) => {
        return responses[0]?.data;
    })
}

const afterLogin = (responses: AxiosResponse[], dispatchTool?) => {
    const res = responses[0]?.data;
    setJwtToken(res.token);
    return res.user.member_profile;
}

export function login(
    email: string,
    password: string,
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_LOGIN_WITH_EMAIL',
                data: {
                    "login": email,
                    "password": password,
                }
            }
        ],
    ).then((responses: AxiosResponse[]) => afterLogin(responses));
}

export function googleLogin(
    googleReturn: any,
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_LOGIN_WITH_GOOGLE',
                data: googleReturn
            }
        ],
    ).then((responses: AxiosResponse[]) => afterLogin(responses));
}

export function facebookLogin(
    facebookReturn: any,
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_LOGIN_WITH_FACEBOOK',
                data: facebookReturn
            }
        ],
    ).then((responses: AxiosResponse[]) => afterLogin(responses));
}

export function accountActivation(
    activationCode: string
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_ACCOUNT_ACTIVATION',
                data: {
                    "activation_code": activationCode,
                }
            }
        ],
    )
}


export function getUser() {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_GET_USER',
            }
        ],
    ).then((responses) => {
        const user = responses[0]?.data.user;
        let profile = user.member_profile;
        profile.email = profile.email || user.email;
        profile.preferred_lang = profile.preferred_lang || 'en';
        profile.available_points = user.availablePoints;
        profile.point_buckets = user.point_buckets;
        profile.point_transactions = user.point_transactions;
        profile.consent = profile.consent === 1;
        profile.email_verified = profile.email_verified === 1;

        // somehow fields in shipping_address_1 are different from member_profile
        const addr = (profile.shipping_address_1 || []).at(0);
        if (addr && addr.memberProfile) {
            for (let i in addr.memberProfile) {
                profile[i] = addr.memberProfile[i];
            }
        }

        // remove 'null' string
        for (let key in profile) {
            if (profile[key] === 'null') {
                profile[key] = null;
            }
        }

        setUserProfile(profile);
        getI18n().changeLanguage(profile.preferred_lang);
        return profile;
    });
}

export function refreshToken() {
    return makeApiCalls([{ endpoint: 'AUTH_REFRESH_TOKEN' }])
        .then(() => true)
        .catch(() => false);
}

/**
 * A flow for backend from checking user existence to register, login,
 * and get user profile at the end
 * @param user Auth0 User
 * @returns object|null
 */
export async function getUserProfile(user) {
    if (!user) return;

    try {
        const res = await isUserExist(user.email, user.sub);

        // register if not exist
        if (!res.success) await register(user.email, user.sub);

        // login user
        await login(user.email, user.sub);

        // get user profile
        let profile = await getUser();

        // update user email status from auth0
        if (user.email_verified && !profile.email_verified) {
            await updateEmailVerifiedState();
            profile.email_verified = true;
        }

        return profile;
    } catch (ex) {
        console.error(ex);
        return null;
    }
}

export function forgetPassword(
    email: string,
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_FORGET_PASSWORD',
                data: {
                    "email": email,
                }
            }
        ],
    );
}

export function resetPassword(
    activationCode: string,
    password: string,
) {
    return makeApiCalls(
        [
            {
                endpoint: 'AUTH_RESET_PASSWORD',
                data: {
                    "reset_password_code": activationCode,
                    "password": password,
                }
            }
        ],
    );
}

export function logout() {
    removeJwtToken();
    removeMe();
    localStorage.removeItem('auth0Login');
    localStorage.removeItem('migration_profile');
    localStorage.removeItem('submitted_profile');
    localStorage.removeItem('availablePoints');
}

export function getInstrumentList() {

    return makeApiCalls(
        [
            {
                endpoint: 'PUBLIC_GET_INSTRUMENT_LIST',
            }
        ],
    ).then((responses) => {
        return responses[0]?.data;
    })
}

// Underscores indicate fields that are not persisted
const allowedFields = [
    'salutations',
    'first_name',
    'last_name',
    'chinese_name',
    'dob',
    'gender',
    'email',
    'country_code',
    'mobile',
    'flat_or_unit',
    'floor',
    'block_or_phase',
    'house_or_building_estate',
    'road_or_street',
    'district',
    'country',
    'interest_id',
    'preferred_lang',
    'receive_email_promotion',
    'consent',
    'member_no',
    'membership_tier',
    'membership_since',
    '_is_ya_form',
    '_is_paying_red',

    // YA-form specific fields
    'guardian_name',
    'guardian_email',
    'guardian_mobile',
    'school_name',
    'school_type',
    '_student_card_base64',
];
export function updateProfile(profile) {
    let data = {
        ...profile,
        dob: profile.month && profile.year
            ? `${profile.year}-${profile.month}-01`
            : null,
        interest_id: (profile.instruments || []).map(v => v.id),
        receive_email_promotion: profile.receive_email_promotion === true,
        consent: profile.consent === true,
    };
    // filter fields
    for (let field in data) {
        if (allowedFields.indexOf(field) === -1) {
            delete data[field];
        }
    }
    return makeApiCalls(
        [
            {
                endpoint: 'UPDATE_MEMBER_PROFILE',
                data
            }
        ],
    ).then((responses: AxiosResponse[]) => {
        return responses[0]?.data;
    })
}
export function saveChosenRegistrationForm(type) {
    return makeApiCalls(
        [
            {
                endpoint: 'SAVE_CHOSEN_REGISTRATION_FORM_TYPE',
                data: {
                    registration_form_type: type,
                }
            }
        ],
    );
}

export function assignSCTierToUser(
    sc_token,
    user_email
) {
    return makeApiCalls(
        [
            {
                endpoint: 'SET_SC_TOKEN_IS_USED',
                data: {
                    sc_token,
                    user_email
                }
            }
        ],
    ).then((responses: AxiosResponse[]) => {
        return responses[0]?.data;
    });
}

export function assignFreeUpgradeTierToUser(
    free_tier_upgrade_token,
    user_email
) {
    return makeApiCalls(
        [
            {
                endpoint: 'SET_FREE_TIER_UPGRADE_TOKEN_IS_USED',
                data: {
                    free_tier_upgrade_token,
                    user_email
                }
            }
        ],
    ).then((responses: AxiosResponse[]) => {
        return responses[0]?.data;
    });
}

export function setProfileIsMigrated({
    member_no,
    last_name,
}) {
    return makeApiCalls(
        [
            {
                endpoint: 'SET_PROFILE_IS_MIGRATED',
                data: {
                    cb_member_no: member_no,
                    last_name,
                }
            }
        ],
    ).then((responses: AxiosResponse[]) => {
        return responses[0]?.data;
    });
}

export function sendWelcomeEmail(
    tier: string
) {
    return makeApiCalls([
        {
            endpoint: "SEND_WELCOME_EMAIL",
            data: {
                "tier": tier,
            },
        },
    ]).then((responses: AxiosResponse[]) => {

        return responses;
    });
}

export function updateEmailVerifiedState(
) {
    return makeApiCalls([
        {
            endpoint: "UPDATE_USER_EMAIL_VERIFIED",
            data: {
            },
        },
    ]).then((responses: AxiosResponse[]) => {
        return responses;
    });
}

export function getUserInterests() {
    return makeApiCalls(
        [
            {
                endpoint: 'GET_USER_INTERESTS',
                data: {
                }
            }
        ],
    ).then((responses) => {
        return responses[0]?.data;
    });
}
export function getPointTransaction() {
    return makeApiCalls(
        [
            {
                endpoint: 'GET_USER_POINT_TRANSACTION',
                data: {
                }
            }
        ],
    ).then((responses) => {
        return responses[0]?.data;
    });
}


export function getUserTierUpgradeProgress() {
    return makeApiCalls(
        [
            {
                endpoint: 'GET_USER_TIER_UPGRADE_PROGRESS',
                data: {
                }
            }
        ],
    ).then((responses) => {
        return responses[0]?.data;
    });
}

export function redeemAsiaMilesFromWebsite(
    points_to_convert,
    asia_miles_membership_number,
    asia_miles_member_first_name,
    asia_miles_member_last_name
) {
    return makeApiCalls(
        [
            {
                endpoint: 'CREATE_ASIA_MILES_REDEEM_RECORD',
                data: {
                    points_to_convert: points_to_convert,
                    asia_miles_membership_number: asia_miles_membership_number,
                    asia_miles_member_first_name: asia_miles_member_first_name,
                    asia_miles_member_last_name: asia_miles_member_last_name,
                }
            }
        ],
    ).then((responses) => {
        return responses[0]?.data;
    });
}

export function getGaugeLevels(

) {
    return makeApiCalls(
        [
            {
                endpoint: 'GET_GAUGE_LEVELS',
            }
        ],
    ).then((responses) => responses[0]?.data);
}

