const resources = {
  en: {
    translation: {
      lang: {
        en: 'English',
        tc: 'Chinese', 
        sc: 'Chinese'
      },
      html_title: {
        asiamiles: 'Asia Miles Redemption',
        asiapay_error: 'Payment Error',
        asiapay_fail: 'Payment Failed',
        contact_us: 'Contact Us',
        how_to: 'How to',
        home: 'Club Bravo － Hong Kong Philharmonic Orchestra',
        login: 'Log in',
        member_detail: 'My Account',
        member_detail_edit: 'Edit Member Details',
        not_found: 'Page not Found',
        personal_detail: 'Personal Details',
        point_transaction_log: 'Point Transaction Log',
        redeem_fail: 'Redemption Failed',
        redeem_success: 'Redemption Successful',
        registration: 'Registration',
        registration_existing: 'Registration for Existing Members',
        registration_free: 'Register for Free Club Bravo Basic',
        registration_red: 'Subscribe to Club Bravo Red',
        registration_ya: 'Be a Young Audience Scheme Member',
        registration_success: 'Registration Successful',
        reward_detail: ' | Reward Details',
        reward_list: 'Reward List',
        scan_failed: 'Scan Failed',
        scan_intro: 'Ticket Scan',
        scanner: 'Ticket Scan',
        scan_preview: 'Scan Preview',
        scan_success: 'Scan Successful',
        terms_and_conditions: 'Terms & Conditions',
        what_is_club_bravo: 'What is Club Bravo',
      },
      innerHtml: {
        cb_logo: '<span class="cb-logo">b</span>',
        cb_logo_white: '<span class="cb-logo-white">b</span>',
      },
      user: {
        personal_data: "Personal data",
        sign_up: "Sign Up",
        login_to_continue: "Please log in to continue",
        logout: "Log out",
        welcome_back: "Welcome back",
      },
      header: {
        home: "Home",
        what_is_club_bravo: "What is Club Bravo",
        offers: "Rewards",
        scan_your_ticket: "Scan Ticket",
        how_to: "FAQ",
        my_account: "My Account",
        membership_tiers: "Member Tiers",
        show_card: "Show Card"
      },
      footer: {
        terms_and_conditions: "Terms & Conditions",
        disclaimer: "Disclaimer",
        privacy_policy: "Privacy Policy",
        hk_phil_main_website: "HK Phil Main Website",
        copyright: "Copyright © {{year}} The Hong Kong Philharmonic Society Limited. All rights reserved.",
        supports: "The Hong Kong Philharmonic Orchestra is financially supported by the Government of the Hong Kong Special Administrative Region and is a Venue Partner of the Hong Kong Cultural Centre.",
        swire: "Principal Patron: Swire",
      },
      homepage: {
        scan_earn_redeem: "SCAN. EARN. REDEEM.",
        experience_new_cb: "Experience the new Club Bravo. Convert every purchased ticket to points. Redeem Miles, Vouchers and Reward Tickets!",
        join_for_free: "Join for Free!",
        highlight_rewards: "Highlight Rewards",
        what_is_club_bravo: "What is Club Bravo?",
        learn_more: "Learn More",
        six_grid_top_content: "Lorem ipsum dolor sit amet, con sed diam voluptua.t, con sed diam voluptua.Lorem ipsum dolor sit amet, con sed diam voluptua.t, con sed diam voluptua.",
        six_grid_middle_content: "Lorem ipsum dolor sit amet, con sed diam voluptua.t, con sed diam voluptua.Lorem ipsum dolor sit amet, con sed diam voluptua.t, con sed diam voluptua.",
        six_grid_bottom_content: "Lorem ipsum dolor sit amet, con sed diam voluptua.t, con sed diam voluptua.Lorem ipsum dolor sit amet, con sed diam voluptua.t, con sed diam voluptua.",
        point_balance: "Point Balance",
      },
      what_is_club_bravo: {
        menu:{
          the_new_club_bravo: "The New Club Bravo",
          membership_tiers: "Membership Tiers",
          privilege: "Privilege",
          enjoy_urbtix_discount: "Enjoy Urbtix Discount",
        },
        the_new_club_bravo:{
          scan_earn_redeem_title: "SCAN. EARN. REDEEM.",
          scan_earn_redeem_intro: "Revamped in 2022, Club Bravo is the loyalty programme of the Hong Kong Philharmonic Orchestra (HK Phil). It has been the bridge between the orchestra and the supporters since its launch in 2000.",
          scan_as_cb_member: "As a Club Bravo member, you can earn points on every purchase of HK Phil concert tickets, redeem points for rewards and enjoy exclusive privileges.",
          scan: "Scan.",
          earn: "Earn.",
          redeem: "Redeem.",
          scan_intro: "Simply scan and upload your concert tickets to earn points easily. You can review the accumulated points in one go in your personal account!",
          earn_intro: "Earn points on every purchase of HK Phil selected concert tickets to unlock more discounts and rewards.",
          redeem_intro: "Catering to your various needs and tastes, we team up with a wide range of premium brands to provide more diversified rewards and exclusive discount offers from restaurants, retailers and hotels, as well as conversion to Asia Miles.",
          three_tiers_of_benefits_title: "3 TIERS OF BENEFITS",
          tiers_of_benefits_feature: "The programme features three membership tiers – Basic, Red and Gold. As you collect more points, you can upgrade the tier to unlock more discounts and rewards.",
          tiers_of_benefits_privileges: "To foster a deeper and more personal connection with the members, we offer a host of exclusive privileges, including birthday offers, ticket discounts, early event registration, priority subscription, access to “Watch & Listen” videos, HK Phil eNewsletters and member hotline.",
          tiers_of_benefits_join_us: "Join us and unlock the all-encompassing musical experience!",
        },
        membership_tier: {
          membership_tiers_title: "Membership Tiers",
          intro: "Catering to your various needs and tastes, we team up with a wide range of premium brands to provide more diversified rewards and exclusive discount offers from restaurants, retailers and hotels, as well as conversion to Asia Miles.",
          intro_black: "Simply scan and upload your concert tickets to earn points easily. You can review the accumulated points in one go in your personal account!",
          intro_red: "Earn points on every purchase of HK Phil selected concert tickets to unlock more discounts and rewards.",
          intro_gold: "Catering to your various needs and tastes, we team up with a wide range of premium brands to provide more diversified rewards and exclusive discount offers from restaurants, retailers and hotels, as well as conversion to Asia Miles.",
          tier_benefits: "Tier Benefits",
          tier_benefits_table: {
            how_to_join:"HOW TO JOIN",
            membership_validity:"MEMBERSHIP VALIDITY",
            parking_privilege:"PARKING PRIVILEGE",
            earning_b_and_asia_miles:'EARNING <span class="cb-logo">b</span> AND ASIA MILES',
            have_met_the_following:"Having met the following:",
            spending_of:"Accumulated spending of",
            thirty_day:"HK$120,000 in 30 days",
            twelve_month:"Or HK$300,000 in 12 months",
            free_sign_up:"Free Sign Up",
            none:"None",
            permanent:"Permanent",
            up_to_three_hours:"Up to 3 Hours",
            unlimited:"Unlimited",
            valid_up_to_twelve_months:"Valid up to 12 months",
          },
        },
        privileges:{
          title: "Privileges",
          intro: "Catering to your various needs and tastes, we team up with a wide range of premium brands to provide more diversified rewards and exclusive discount offers from restaurants, retailers and hotels, as well as conversion to Asia Miles.",
          scan_for_point_and_rewards: "SCAN YOUR TICKETS FOR POINTS & EARN REWARDS",
          scan_for_point_and_rewards_content: 'Earn <span class="cb-logo">b</span>1 Club Bravo point for every $1 spent on eligible ticket purchase. Scan your ticket to redeem Asia Miles, concert tickets, coupons, merchandise and more.',
          save_on_standard_ticket_purchase: "SAVE AT LEAST 30% ON STANDARD TICKET PURCHASE",
          save_on_standard_ticket_purchase_content: "Get up to 30% discount on standard ticket purchase for selected HK Phil concerts via URBTIX – no minimum purchase required! ",
          welcome_offer: '<span class="cb-logo">b</span>500 WELCOME OFFER',
          welcome_offer_content: 'Complete your membership profile to earn <span class="cb-logo">b</span>500 Club Bravo points as a welcome bonus!',
          priority_registration: "PRIORITY REGISTRATION TO FREE CONCERTS & EVENTS",
          priority_registration_content: 'Enjoy priority registration for our free concerts, including Swire Symphony Under The Stars or the "Swire Classic Insights" series.',
          asia_miles_conversion: "ASIA MILES CONVERSION",
          asia_miles_conversion_content: 'Enjoy better flexibility by converting Club Bravo Points to Asia Miles at an extraordinary rate of <span class="cb-logo">b</span>4 points = <span class="asiamile-logo">a</span>1 mile!',
          priority_subscription: "PRIORITY SUBSCRIPTION",
          priority_subscription_content: "Select best seats before everyone else with priority subscription in our upcoming season!",
          member_exclusive: "MEMBER EXCLUSIVE “WATCH & LISTEN” PERFORMANCES",
          member_exclusive_content: "Enjoy our member-exclusive full performances for free online! From family concerts, our annual Swire Symphony Under The Stars to symphonies and concertos, we’ve got you covered!",
          free_beverage_coupon: "FREE BEVERAGE COUPON",
          free_beverage_coupon_content: "Enjoy free access to a diverse range of exclusive activities, such as Open Rehearsals, Artist Talks and other special events for more unique experiences and engagements with the HK Phil.",
        },
        enjoy_urbtix_discount:{
          title: "How to Enjoy Discounts* at Urbtix?",
          outlets: "Outlets",
          outlets_intro: "PRESENT YOUR CLUB BRAVO MEMBERSHIP CLUB",
          outlets_opening_hours: "General Opening Hours for the outlets",
          outlets_enquiry: "Enquiry for locations and opening hours: ",
          self_service_ticketing_kiosks: "Self-service Ticketing Kiosks",
          self_service_ticketing_kiosks_intro: "INPUT YOUR CLUB BRAVO MEMBERSHIP NUMBER",
          self_service_ticketing_kiosks_content: "Self-service Ticketing Kiosks will be operated during the opening hours of venues opening hours of venues are subject to the announcement on the individual venue website",
          telephone_booking: "Telephone Booking",
          telephone_booking_input_cb_membership_number: "INPUT YOUR CLUB BRAVO MEMBERSHIP NUMBER",
          telephone_booking_number: "Telephone Booking Number: ",
          web_and_app: "Website and Mobile App",
          web_and_app_intro: "INPUT YOUR CLUB BRAVO MEMBERSHIP NUMBER",
          urbtix_site: "URBTIX website: ",
          latest_version: "Latest version of the mobile ticketing app URBTIX can be downloaded from ",
          or: "or",
        }
      },
      rewards: {
        redeem: "Reward",
        special_offer: "Special Offer",
        all_year_round: "All Year Round",
        arts_and_culture: 'Merchant Offer - Arts & Culture',
        bookstore_and_cultural_institution: 'Merchant Offer - Bookstore & Cultural Institution',
        lifestyle: 'Merchant Offer - Lifestyle',
        music_centre_and_institution: 'Merchant Offer - Music Centre and Institution',
        restaurant: 'Merchant Offer - Restaurant',
        event: 'Event',
        back_to_rewards: "Back to rewards",
        description: "DESCRIPTION",
        remarks: "REMARKS",
        terms_and_conditions: "TERMS & CONDITIONS",
        quantity: "Quantity",
        please_verify_email: 'Please verify the Email to redeem',
        item_not_found: 'Item not found',
        popup_confirm_title: 'Confirm Redemption',
        popup_confirm_message: 'Please confirm your reward redemption',
        popup_retry_title: 'Redeem Fail',
        popup_retry_message: 'There is only {{ quantity }} left, please adjust the quantity and try again.',
        sold_out_title: 'Sold Out',
        sold_out_message: 'It has been sold out.',
        redeem_ref_no: 'Reference Number: ',
        redeem_gift_name: 'Gift/Offer: ',
        points_redeemed: 'Club Bravo Points Redeemed: ',
        remaining_points: 'Remaining Club Bravo Points: ',
        mailing_address: 'Your Address:  ',
        coupon: 'Your Coupon(s):  ',
        add_another_redeem: 'ADD ANOTHER REDEEM',
        back_to_reward_list: 'BACK TO REWARDS',
        shipping_to: 'Mailing Address',
        delivery_option: 'Delivery: ',
        delivery: 'Delivery',
        on_site_pick_up: 'On-site pick-up',
        email: 'Email',
        please_enter_address: 'Please enter shipping address',
        reward_name: 'Reward',
        please_accept_tnc: 'Accept the terms and conditions to redeem',
        agree_tnc: 'I agree to the terms and conditions of redemption.'

      },
      about_club_bravo_general: {
        title: "About Club Bravo",
        paragraph_1: "Revamped in 2022, Club Bravo is the loyalty programme of the Hong Kong Philharmonic Orchestra (HK Phil). It has been the bridge between the orchestra and the supporters since its launch in 2000. As a Club Bravo member, you can earn points on every purchase of HK Phil concert tickets, redeem points for rewards and enjoy exclusive privileges. The programme features three membership tiers – Black Red and Gold. As you collect more points, you can upgrade the tier to unlock more discounts and rewards.",
        paragraph_2: "To foster a deeper and more personal connection with the members, we offer a host of exclusive privileges, including birthday offers, ticket discounts, early event registration, priority subscription, access to “Watch & Listen” videos, HK Phil eNewsletters and member hotline.",
        paragraph_3: "We strive to make it easy for members to earn and redeem points at their fingertips. The newly designed all-in-one website allows you to sign up for membership, scan your concert tickets to earn points, redeem points, and keep track of your points and membership status on a single platform, with more features coming soon !",
        paragraph_4: "Join us and unlock the all-encompassing musical experience!",
        scan_earn_redeem: "SCAN. EARN. REDEEM.",
        simply_scan: "Simply scan and upload your concert tickets to earn points easily. You can review the accumulated points in one go in your personal account!",
        earn_points_on : "Earn points on every purchase of HK Phil selected concert tickets to unlock more discounts and rewards",
        catering_to_your  : "Catering to your various needs and tastes, we team up with a wide range of premium brands to provide more diversified rewards and exclusive discount offers from restaurants, retailers and hotels, as well as conversion to Asia Miles.",
        and_more: "AND MORE",
        discounts: "DISCOUNTS",
        dedicated_hotline: "DEDICATED HOTLINE",
        priority: "PRIORITY",
        unique_experiences: "UNIQUE EXPERIENCES",
        latest_news: "LATEST NEWS",
        fabulous_benefits: "FABULOUS BENEFITS",
      },
      about_club_bravo_tier: {
        tier: "Member Status",
        club_bravo_Black: "Club Bravo Basic",
        club_bravo_Red: "Club Bravo Red",
        club_bravo_Gold: "Club Bravo Gold",
        available_point: "Available Points",
        accumulated_points_in_single_season: "Accumulated points in single season",
        number_of_tickets_bought_in_single_season: "Number of tickets bought in single season",
        number_of_programmes_bought_in_single_season: "Number of programmes bought in single season",
        member_privileges: "Member Privileges",
        five_hundred_welcome_offer_title: '<span class="cb-logo">b</span>500 Welcome Offer',
        five_hundred_welcome_offer_content: 'Complete your membership profile to earn <span class="cb-logo">b</span>500 Club Bravo points as a welcome bonus!',
        member_exclusive_watch_and_listen_performances_title: 'Member Exclusive "Watch & Listen" Performances_title',
        member_exclusive_watch_and_listen_performances_content: "Enjoy our member-exclusive full performances for free online! From family concerts, our annual Swire Symphony Under The Stars to symphonies and concertos, we've got you covered!",

        all_club_bravo_black_benefits_plus: "All Club Bravo Basic benefits, plus:",
        thousand_upgrade_bonus_for_club_bravo_red_title: '<span class="cb-logo">b</span>1000 Upgrade Bonus for Club Bravo Red',
        thousand_upgrade_bonus_for_club_bravo_red_content: 'Earn a <span class="cb-logo">b</span>1000 bonus once you are upgraded to Club Bravo Red!',
        all_club_bravo_red_benefits_plus: "All Club Bravo Red benefits, plus:",
        two_thousand_upgrade_bonus_for_club_bravo_gold_title: '<span class="cb-logo">b</span>2000 Upgrade Bonus for Club Bravo Gold',
        two_thousand_upgrade_bonus_for_club_bravo_gold_content: 'Earn a <span class="cb-logo">b</span>2000 bonus once you are upgraded to Club Bravo Gold!',
        complete_profile_reminder: "Please complete your profile to activate your Club Bravo membership."
      },
      registration_membership_options: {
        join_club_bravo_title: "Join Club Bravo",
        join_club_bravo_description: "As a Club Bravo member, you can earn points on every purchase of HK Phil concert tickets, redeem points for rewards and enjoy exclusive privileges.",
        free_membership_title: "Free Membership",
        free_membership_content: 'Sign up for the Club Bravo Basic membership -  No hassle, No fees! Instantly earn <span class="cb-logo">b</span>500 welcome offer (equivalent to <span class="asiamile-logo">a</span>125 Asia Miles) by finishing the application form!',
        free_upgrade_to_higher_tiers: "Free upgrade to higher tiers",
        paid_membership_title: "Paid Membership",
        paid_membership_content: "Excited for more exclusive offers like 15% off for tickets or priority registration for free concerts? Subscribe to the upgraded Club Bravo Red membership at an annual membership fee of HK$300.",
        current_members_title: "Club Bravo Members",
        current_members_content: "Attention to Club Bravo members in or before 2022: Redeem your upgraded Club Bravo Red / Gold membership for free here and enjoy your deserved (and more!) benefits!",
        standard_chartered_cathay_mastercard_holders_title: "Standard Chartered Cathay Mastercard Holders",
        standard_chartered_cathay_mastercard_holders_content: "Enjoy a free membership upgrade if you are a card holder of Standard Chartered Cathay Mastercard!",

        existing_member_title: "Existing Club Bravo members",
        existing_applied_before_date: "(Membership validity: 31 AUG 2024)",
        existing_member_intro: "Activate your upgraded Club Bravo Red or Gold account for free!",
        activate_your_account: "Activate your account",

        free_member_title: "Register for free Club Bravo Basic",
        free_member_intro: "Start your “Scan, Earn, Redeem” journey. No hassle, No fees!",
        register_for_free: "Register for Free",
        
        paid_member_title: "Subscribe to Club Bravo Red",
        paid_member_intro: "Enjoy extra benefits like 15% ticket discount instantly with the fee of HK$300! </br><b>Only members who become a Red or Gold Club Bravo Members on or before 17 OCT 2023 can entitle to 【Members Priority Registration】for 2023 SWIRE SYMPHONY UNDER THE STARS.</b>",
        paid_applied_before_date: "(Membership validity: 31 AUG 2023)",
      },
      registration_auth0: {
        register_for_new_club_bravo_account: "Register for New Club Bravo Account",
        register_for_new_club_bravo_content: "To open a new Club Bravo account, please input your email address and set up a password",
        enter_your_email: "Enter your email",
        set_up_your_password: "Set up your password",
        confirm_your_password: "Confirm your password",
        i_would_like_to_subscribe_to_e_news_from_hong_kong_philharmonic_orchestra:"I would like to subscribe to e-News from Hong Kong Philharmonic Orchestra",
        register: "Register",
      },
      registration_existing_step_zero: { // for existing member
        current_members_title: "Current Members",
        current_members_content_1: "Existing Club Bravo members in or before 2022 can redeem your upgraded Club Bravo membership for free.",
        current_members_content_2: "You should have received, by mail and email, your membership number and free upgrade code for your Club Bravo membership. Please fill in the information below to start your free membership upgrade.",
        enjoy_your_deserved_and_more_benefits: "Enjoy your deserved (and more!) benefits!",
        club_bravo_membership_number_with_example: "Club Bravo Membership Number",
        your_last_name_with_example: "Your Last Name ",
        free_upgrade_code_with_example: "Free Upgrade Code (e.g. asfaos8d21308yv213!~)",
        create_upgraded_membership_account_for_free: "Create Upgraded Membership Account for Free",
      },
      registration_sc_form: {
        registration: 'Standard Chartered Cathay Mastercard',
        left_title: 'Welcome to Club Bravo!',
        left_content:
            'Enjoy unique privileges with Standard Chartered Cathay Mastercard when you purchase concert tickets from the Hong Kong Philharmonic Orchestra, ranging from miles rewards to free membership tier matching for ticket discount and priority registration of free events!<br><br>' +
            'If you are not yet a member of Club Bravo, please click \"Register Now\" to create an account. If you are already a Club Bravo member, please sign-in to upgrade your membership for free!',
        token_left_content:
            'Enjoy unique privileges with Standard Chartered Cathay Mastercard when you purchase concert tickets from the Hong Kong Philharmonic Orchestra, ranging from miles rewards to free membership tier matching for ticket discount and priority registration of free events!<br><br>' +
            'The steps to join/ upgrade your club bravo membership as follows:<br><br>' +
            '1. Please register/login to your Club Bravo account<br><br>' +
            '2. Go to the confirmation email sent by Cathay Shop and click "Redemption"<br><br>' +
            '3. Follow and complete the registration form to obtain your new/upgraded Club Bravo membership. ',
        right_title: "Club Bravo Privileges",
        right_content: "You can enjoy 15% discount for concert tickets, priority registration of free events and many other exclusive benefits!",
        footnote: "*Please note that once your Club Bravo account is registered or upgraded, it cannot be transferred to another account.",

      },
      registration_step_three: { // for free, paid and existing member
        i_would_like_to_receive_email_promotion: "I would like to receive email promotion",
        do_not_wish_receive_promotion: "If you do not wish to receive any promotional and direct marketing materials from us, please check this box",
        i_confirm_email_correct: '*I confirm my email address is correct:',
        i_agree_to_the_hk_phil_1: "* I agree to the HK Phil ",
        privacy_policy: "Privacy Policy",
        i_agree_to_the_hk_phil_2: " and ",
        terms_of_conditions : "Terms of Conditions.",
        complete_all_items_in_the_form_for_free_welcome_offer_title: "Complete All Items in the Form for Free Welcome Offer!",
        complete_all_items_in_the_form_for_free_welcome_offer_content: 'Complete your membership profile to earn <span class="cb-logo">b</span>500 Club Bravo points as a welcome bonus! (Equivalent to <span class="asiamile-logo">a</span>125 Asia Miles!)',
      },
      registration_success: {
        scan_ticket: "Scan Ticket",
        view_rewards: "Browse Rewards",
      },
      registration_paid_failed: { // for paid member
        restart_membership_application: "Restart Membership Application",
      },
      registration_common: {
        join_club_bravo: "Join Club Bravo",
        free_member_registration: "Free Member Registration",
        red_member_registration: "MEMBER REGISTRATION",
        club_bravo_red_member_registration: "Club Bravo Red Member Registration",
        registration: "Registration",
        register: "Register",
        about_you: "About You",
        contact_details: "Contact Details",
        your_preference: "Your preference",
        required_field: "*Required field",
        previous_step: "Previous Step",
        next_step: "Next Step",
        submit: "Submit",
        submit_and_pay: "Submit and Pay",
        free_member_intro_title: "Free Membership",
        free_member_intro_1: "Register for free Club Bravo Basic",
        free_member_intro_2: "Start your “Scan, Earn, Redeem” journey. No hassle, No fees!",
        register_for_free: "Register for Free",
        activate_your_account: "Activate your account",
        paid_member_intro_title: "Paid Membership",
        paid_member_intro_1: "Subscribe to Club Bravo Red",
        paid_member_intro_2: "Enjoy extra benefits like 15% ticket discount instantly with the fee of HK$300!(Membership validity: 31 AUG 2024)",
        register_now: "Register Now",
        asia_miles_conversion_content: 'Enjoy better flexibility by converting Club Bravo Points to Asia Miles at an extraordinary rate of <span class="cb-logo">b</span>4 points = <span class="asiamile-logo">a</span>1 mile!',
        title: 'BE OUR CLUB BRAVO MEMBER',
        subtitle: 'Please choose your membership type.',
        migrate_description: 'Selected Club Bravo members can redeem your upgraded Club Bravo Red / Gold membership for free. Please input your membership information below to continue.',
        migrate_description_title: 'What follows after the free upgrade?',
        migrate_description_content: 'You can enjoy at least 15% discount for concert tickets, priority registration of free events, priority subscription and many other exclusive benefits!',
        tier_selection: 'Membership Type',

        // YA form
        ya_registration_title: "BE A YOUNG AUDIENCE SCHEME MEMBER",
        ya_registration_subtitle: "MEMBER REGISTRATION",
        school_details_and_your_preference: "School Details + Your preference",
        ensure_correct_student_info: 'Please make sure the information below is identical with your student ID card',
      },
      registration_message: {
        required_field: "* Required Field",
        invalid_first_name: "Invalid first name!",
        invalid_last_name: "Invalid last name!",
        invalid_chinese_name: "Invalid Chinese name! Please ensure you only inputted Chinese Word.",
        invalid_email: "Invalid email address!",
        invalid_mobile: "Invalid mobile!",
        submit_failed: "Submission is failed! ",
        salutation_invalid: "Salutation is NOT valid!",
        first_name_missing: "First name is missing!",
        first_name_invalid: "First name is NOT valid!",
        last_name_missing: "'Last name is missing!",
        last_name_invalid: "Last name is NOT valid!",
        dob_missing: "Date of Birth is missing!",
        dob_invalid: "Date of Birth is NOT valid!",
        gender_invalid: "Gender is NOT valid!",
        email_missing: "Email is missing!",
        email_has_been_used: "Email has already been used!",
        email_invalid: "Email is NOT a valid!",
        mobile_missing: "Mobile is missing!",
        mobile_has_been_used: "Mobile has already been used!",
        mobile_invalid: "Mobile is NOT valid!",
        prefer_lang_invalid: "Preferred Language is NOT valid!",
        receive_email_promotion_invalid: "Opt out from Promotional Email setting is NOT valid!",
        consent_invalid: "Consent Promotion setting is NOT valid!",
        migrate_successful: "This existing profile has been migrated",
      },
      sign_in: {
        // for auth0
        sign_in_to_your_club_bravo_account_title: "Sign in to your Club Bravo Account",
        sign_in_to_your_club_bravo_account_content: "To sign in to your Club Bravo account, please input your email address and password",
        your_email_address: "Your email address",
        your_password: "Your password",
        sign_in: "Sign in",
        forget_password: "Forget password?",
        not_yet_joined_club_bravo: "Not yet joined Club Bravo?",
        register_now: "Register now",
        // for Forget Password
        forget_your_password_title: "Forgot your password?",
        forget_your_password_content: "Please provide your email address associated with your Club Bravo account. You will receive an email containing a link to change your password.",
        enter_your_email_address: "Enter your email address",
        back: "Back",
        submit: "Submit",
        // email_template_tbc: "Email template TBC",
        access_expiry: "Access Expiry",
        authentication_error_title: "Authentication Error",
        authentication_error_message: "The authentication link has expired. Please make a new request to change your password.",
      },
      landing_page: {
        membership_valid_until: "Membership valid until:",
        until_next_level: "Until Next Level",
        tickets: "TICKETS",
        programs: "PROGRAMMES",
        point: "POINTS",
        membership: "Membership",
        membership_status: "MEMBERSHIP STATUS",
        club_bravo_Black : "Club Bravo Basic",
        club_bravo_Red: "Club Bravo Red",
        club_bravo_Gold: "Club Bravo Gold",
        expiry_date: "EXPIRY DATE",
        renewal_remarks: "It will be automatically renewed on 1 September each year",
        redeem_rewards: "Redeem Rewards",
        asia_miles: "Redeem Asia Miles Now!",
        convert_to_asia_miles: "Convert To Asia Miles",
        view_personal_details : "View Details",
        personal_details : "Personal Details",
        please_fill_out: "Please fill out the following information to get started on your membership.",
        edit: "Edit",
        edit_profile: "Edit Profile",
        account_details: "Account Details",
        account_number: "Membership Number",
        salutations: "Salutation",
        prof: "Prof.",
        select: "Select",
        dr: "Dr.",
        mr: "Mr.",
        mrs: "Mrs.",
        ms: "Ms.",
        name: "Name",
        first_name: "First Name (English)",
        last_name: "Last Name (English)",
        chinese_name: "Chinese Name",
        date_of_birth: "Date of Birth",
        gender:"Gender",
        male: "Male",
        female: "Female",
        other: "Other",
        email: "Email Address",
        email_address: "Email Address",
        phone: "Phone",
        address: "Address",
        country_code: "Country/Area Code",
        mobile: "Mobile Number",
        flat_or_unit: "Flat/Unit",
        floor: "Floor",
        block_or_phase: "Block/Phase",
        house_or_building_estate:"House/Building Estate",
        road_or_street: "Street/Road", // TODO  street_or_road: "Street/Road", // check
        district: "District",
        city: "City",
        country: "Country/Region",
        your_interest: "Your Interests",
        area_of_interest: "Area of Interest",
        symphonic_works: "Symphonic works",
        concerto: "Concerto",
        chamber_music: "Chamber Music",
        pop_music: "Pop Music",
        preferred_language: "Preferred Language",
        english: "English",
        chinese: "Chinese",
        consent_for_receiving_email: "Opt out from Promotional Email",
        yes: "Yes",
        no: "No",
        upgrade_membership: "Upgrade Membership",
        renew: "Renew",
        membership_card: "Show Membership Card",
        complete_profile_rewards: 'Get <span class="cb-logo">b</span>500 welcome gift by completing your profile (equivalent to <span class="asiamile-logo">a</span>125 miles!)',
        consent_setting: "Consent setting",
        receive_email_promotion_setting: "Opt out from Promotional Email setting",
        email_or_mobile: "Email or Mobile",
        back_to_member_detail: 'Back To My Account',
        missing_membership_card_hint: 'Membership card not available',
        complete_profile_to_upgrade: 'Complete Profile for Instant Membership Upgrade',
        email_or_mobile_placeholder: "Please refer to your Account Activation letter or email",
        full: "Full",
        manage_membership: "Manage Membership",

        // YA form
        guardian_name: "Guardian's Name",
        guardian_mobile: "Guardian's Mobile",
        guardian_email: "Guardian's Email",
        school_name: "School Name",
        school_type: "School Type",
        student_card_file: "Student ID",

        // Member detail page
        sc_upgrade_offer: "Standard Chartered Cathay Mastercard Holders",
        sc_upgrade_offer_btn_upgrade_now: "Upgrade Membership Now",
        sc_upgrade_offer_btn_details: "Details",
        sc_upgrade_offer_btn_tnc: "Terms & Conditions",
      },
      change_password: {
        change_of_password: "Change of Password",
        basic_information: "Basic Information",
        please_enter_a_new_password_for_your_club_bravo_account: "Please enter a new password for your Club Bravo account.",
        your_new_password: "Your new password",
        confirm_your_new_password: "Confirm your new password",
      },
      point_transaction_log: {
        point_transaction_log: "Point Transaction Log",
        view_point_transaction_log: "View Point Transaction Log",
        date: "Date",
        description: "Description",
        point_change: "Point Change",
        balance: "Balance",
        date_format: "Date format",
        transaction_types: "Transaction Types ",
        profile_completion: "Profile Completion",
        ticket_scanned: "Ticket Scanned",
        ticket_scanned_with_bonus: "Ticket Scanned (With bonus points)",
        reward_redemption: "Reward Redemption",
        asia_miles_redemption: "Asia Miles Redemption",
        bulk_import: "System Ticket Scanned (23/24 Subscription) ",
        return_refund_exchange: "Points Deduction (Return/Refund/Exchange)",
        christmas_birthday_reward_2023: "2023 Christmas Club Bravo Double Points Birthday Reward",
        birthday_double_points: "Birthday Double Points",
        season_subscription_points: "2324 Season Subscription Points",
      },
      scan_tutorial: {
        start_now: "Start Now",
        tips_and_steps: "TIPS AND STEPS",
      },
      scan_your_ticket: {
        ref_number: "Reference Number: ",
        concert_name: "Concert Name: ",
        performance_date: "Performance Date: ",
        performance_time: "Performance Time: ",
        seat_number: "Seat Number: ",
        price: "Price: ",
        success_cb_point_reward: "Club Bravo Points Rewarded:",
        fail_ticket_submission_will_not_be_counted_message: "The ticket submission will not be counted for Club Bravo points. Please check your tickets and consider to try your submission again.",
        enjoy_the_concert: "Enjoy the concert!",
        scanner_hint: "Please scan within the frame",
        upload_photo: "Upload Photo",
        start_scanning: "Start Scanning",
        stop_scanning: "Stop Scanning",
        upload_photo_hint: "Please scan or upload your ticket",
        upload_photo_reminder: 'Cannot scan? Try “Upload Photo”!',
        invalid_qrcode: "Invalid QR Code",
        scan_another: 'SCAN ANOTHER TICKET',
        ticket: 'Ticket',
        tnc: 'Terms & Conditions',
        login_before_scan: 'Please login before using scanner',
        scan_hint: 'Please make sure your email is verified',
        pending_and_invalid: 'Please confirm if you would like to proceed with the ticket submission by clicking "confirm". If not, please click "Retake" to submit again.'
      },
      offer_convert_to_miles: {
        points_to_convert: "Points to Convert",
        asia_miles_intro: 'Enjoy better flexibility by converting Club Bravo Points to Asia Miles at an extraordinary rate of <span class="cb-logo">b</span>{{ rate }} points = <span class="asiamile-logo">a</span>1 mile!',
        conversion_rate: 'Conversion Rate: <span class="cb-logo">b</span>{{ rate }} = <span class="asiamile-logo">a</span>1 Mile',
        suggest_points_to_convert: "Suggested Points to Convert",
        asia_miles_to_be_converted: "Asia Miles to be converted:",
        miles: "miles",
        points: "Points",
        available_point_before_conversion: "兌換前之港樂會積分結餘：",
        available_point_after_conversion: "Available Points after conversion:",
        your_asia_miles_member_first_name: "Your Cathay Member First Name",
        your_asia_miles_member_last_name: "Your Cathay Member Last Name",
        your_asia_miles_membership_number: "Your Cathay Membership Number",
        confirm_asia_miles_account_correct: "I confirm Cathay Member Name and Membership Number are correct",
        understand_the_conversion_is_non_changeable_and_non_reversible: "I understand the conversion of Club Bravo Points to Asia Miles is non-changeable and non-reversible",
        i_agree_to_the: "I agree to the ",
        term_and_condition: "Terms & Conditions",
        redeem_now: "REDEEM NOW",
        come_back_with_more_cb_points: "Come back with more Club Bravo Points!",
        redeem__confirm_checkbox: "Do you confirm the redemption?",
        sold_out: "SOLD OUT",
        back_to_offers: "Back to Offers",
        asia_miles_convert_ref_no: "Reference Number: ",
        asia_miles_name: "Asia Miles Account Name: ",
        asia_miles_no: "Asia Miles Account Number: ",
        asia_miles_converted: "Asia Miles converted: ",
        point_to_convert: "Club Bravo Points used in Conversion: ",
        remaining_points: "Available Club Bravo Points after Conversion: ",
      },
      month: {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      },
      country: {
        // select: 'Select', // check
        hong_kong_sar: 'Hong Kong SAR',
        taiwan: 'Taiwan',
        china: 'China',
      },
      index: "Home",
      button: {
        close: 'Close',
        submit: 'Submit',
        save: 'Save',
        confirm: 'Confirm',
        cancel: 'Cancel',
        retake: 'Retake',
        login: 'Login',
        logout: 'Logout',
        back: 'Back',
        edit: 'Edit',
        verify_later: 'Verify Later',
        start_now: "Start now",
        go_to_profile: "Go to Profile"
      },
      error: {
        validation_error: 'Validation Error',
        invalid_point_conversion: 'For Asia Miles Conversion, please convert the Club Bravo Points that are divisible by 4. Please enter the amount of Club Bravo Points again. Thanks!',
        required: '{{field}} is required',
        invalid: '{{field}} is not valid',
        missing: '{{field}} is missing',
        already_been_used: '{{field}} has already been used',
        min_points_not_reached: 'For Asia Miles Conversion, a minimum of 1000 Club Bravo Points is required. Please enter the amount of Club Bravo Points again. Thanks!',
        missing_tnc: 'Please read Terms and Conditions and check the below checkboxes',
        confirmedEmailIsCorrect: 'Please confirm email is correct and check the below checkboxes',
        invalid_asia_miles: 'Invalid Asia Miles Redemption',
        incorrect_account_data: 'Wrong data for Link Account',
        profile_migrate_failure: 'save Profile Is Migrated is failed!',
        not_enough_points: 'You do not have enough Club Bravo Points to redeem this item', // TODO remove
        invalid_tier: 'Your current account is a Club Bravo Gold Member Gold Card, so you are unable to redeem the "Club Bravo Red Membership". Please check if you have logged in to the correct account. If you have any questions, please feel free to email us at bravo@hkphil.org for assistance.',
        duplicate_token: 'This Club Bravo membership has already been redeemed. Please visit Cathay Shop to redeem another Club Bravo Membership. Thank you.',
      },
      events: {
        event_name: 'Event',
        tickets: 'Ticket(s)',
        registration_coming_soon: 'Registration coming soon',
        popup_confirm_title: 'Confirm Registration',
        popup_confirm_message: 'Please confirm your event registration',
        event_quota_full: 'Event quota is full.',
        user_quota_full: 'User quota is full.',
        successful_registration: 'Successfully registered.',
        waiting_list: 'Successfully added to waiting list.',

      },
      scanner: {
        verify_email: 'Please verify the email',
      },
      verify_email: {
        title: 'Verify Your Email',
        content: 'A verification email has been sent. Please check your mailbox to verify the email before you register.',
        resend: 'Resend Verification Email',
        reload: 'Reload',
        reload_hint: 'Reload if you have already verified your email',
        popup_title: 'The verification email has been re-sent.',
        popup_message: 'Please check your email box and confirm your email address.',
      },
      // These are translations of model constants. They are introduced to simplify the code design. The downside
      // is that we'll need to keep these in sync with the backend models manually.
      model_constants: {
        // For readability, this should be identical to the Oct model name
        MemberProfile: {
          // For readability, this should be identical to the array constant in the model
          SCHOOL_TYPES: {
            // The keys here must be the same as the keys in the model constant
            kindergarten: 'Kindergarten',
            primary_school: 'Primary School',
            secondary_school: 'Secondary School',
            post_secondary: 'Post-secondary',
          },
        },
      },
    },
  },
  tc: {
    translation: {
      lang: {
        en: '英文',
        tc: '中文', 
        sc: '中文'
      },
      html_title: {
        asiamiles: '兌換「亞洲萬里通」里數',
        asiapay_error: '付款失敗',
        asiapay_fail: '付款失敗',
        contact_us: '聯絡我們',
        how_to: '教學',
        home: '港樂會 － 香港管弦樂團',
        login: '登入',
        member_detail: '我的帳戶',
        member_detail_edit: '編輯個人資料',
        not_found: '未能找到頁面',
        personal_detail: '個人資料',
        point_transaction_log: '積分誌賬',
        redeem_fail: '兌換獎賞失敗',
        redeem_success: '兌換獎賞成功',
        registration: '會員登記',
        registration_existing: '現有港樂會會員登記',
        registration_free: '基本會員免費登記',
        registration_red: '即時成為紅卡會員',
        registration_ya: '[TC] Be a Young Audience Scheme Member',
        registration_success: '會員登記成功',
        reward_detail: ' | 獎賞詳情',
        reward_list: '換取獎賞',
        scan_failed: '門票掃描不成功',
        scan_intro: '掃描門票',
        scanner: '掃描門票',
        scan_preview: '預覽掃描門票資料',
        scan_success: '門票掃描成功',
        terms_and_conditions: '條款及細則',
        what_is_club_bravo: '全新港樂會',
      },
      innerHtml: {
        cb_logo: '<span class="cb-logo">b</span>',
        cb_logo_white: '<span class="cb-logo-white">b</span>',
      },
      user: {
        personal_data: "個人資料",
        sign_up: "登入 / 登記",
        login_to_continue: "請登入以繼續",
        logout: "登出",
        welcome_back: "歡迎回來",
      },
      header: {
        home: "主頁",
        what_is_club_bravo: "關於港樂會",
        offers: "獎賞",
        scan_your_ticket: "掃描門票",
        how_to: "教學",
        my_account: "我的帳戶",
        membership_tiers: '會員種類',
        show_card: '出示卡片'
      },
      footer: {
        terms_and_conditions: "條款與細則",
        disclaimer: "免責條款",
        privacy_policy: "私隱政策",
        hk_phil_main_website: "港樂主網頁",
        copyright: "© {{year}} 香港管弦樂團 版權所有 香港管弦樂團由香港特別行政區政府資助，亦為香港文化中心場地伙伴。",
        supports: "",
        swire: "首席贊助：太古集團",
      },
      homepage: {
        scan_earn_redeem: "掃門票．賺積分．換獎賞",
        experience_new_cb: "立即體驗全新港樂會，將每張已購買的門票化為積分，換取里數、禮券及獎勵門票等禮遇！",
        join_for_free: "免費加入",
        highlight_rewards: "精選禮遇",
        what_is_club_bravo: "關於港樂會",
        learn_more: "查看更多",
        point_balance: "積分結餘",

      },
      rewards: {
        redeem: "獎賞",
        special_offer: "特別優惠",
        all_year_round: "全年獎賞",
        arts_and_culture: '商戶優惠－消閒娛樂',
        bookstore_and_cultural_institution: '商戶優惠－書店及文化機構',
        lifestyle: '商戶優惠－精品及消閒娛樂',
        music_centre_and_institution: '商戶優惠－琴行及音樂機構',
        restaurant: '商戶優惠－食肆',
        event: '事件',
        back_to_rewards: "回到獎賞",
        description: "詳細資料",
        remarks: "備註",
        terms_and_conditions: "條款及細則",
        quantity: "數量",
        please_verify_email: '請先驗證的電子郵件',
        item_not_found: '出現錯誤！ 沒有此禮品/禮遇',
        popup_confirm_title: '您確認要兌換此禮品/禮遇嗎？',
        popup_confirm_message: '請確認您兌換的獎賞',
        popup_retry_title: '兌換失敗',
        popup_retry_message: '只剩餘{{quantity}}件，請調整數量後再試。',
        sold_out_title: '售罄',
        sold_out_message: '此禮品/禮遇已售罄了',
        redeem_ref_no: '參考編號：',
        redeem_gift_name: '禮品/禮遇名稱：',
        points_redeemed: '使用之港樂會積分：',
        remaining_points: '港樂會積分結餘：',
        mailing_address: '郵寄地址：',
        coupon: 'Your Coupon(s):  ',
        add_another_redeem: '繼續兌換其他禮品/禮遇',
        back_to_reward_list: '回到禮遇一覽',
        shipping_to: '郵寄地址',
        delivery_option: 'Delivery: ',
        delivery: 'Delivery',
        on_site_pick_up: 'On-site pick-up',
        email: 'Email',
        please_enter_address: '請填寫郵寄地址',
        reward_name: '獎賞',
        please_accept_tnc: '接受條款和條件以兌換',
        agree_tnc: '我同意兌換此獎賞之條款及細則'
      },
      about_club_bravo_general: {
        title: "關於港樂會",
        paragraph_1: "港樂會為香港管弦樂團（港樂）的會員計劃。自2000年成立至今，一直秉承作為港樂與樂迷之間的橋樑，且於2022年革新，為會員提供煥然一新的體驗。入會後，可憑港樂音樂會門票消費賺取積分，兌換專屬獎賞，享受獨家福利。會籍採用分級制，設有黑級、紅級及金級——賺取指定積分數值即可升級，盡擁更多豐富優惠及禮遇。",
        paragraph_2: "為拉近與會員之間的距離，提供更個人化的互動服務，港樂會為會員度身訂造多項專屬禮遇：生日禮遇、門票折扣優惠、優先登記音樂會及活動、樂季優先訂票、「賞樂聽樂」影片觀看權限、港樂電子通訊、會員服務熱線等。",
        paragraph_3: "我們致力為會員打造更方便輕鬆的體驗，推出全新設計的網站。一站式服務涵蓋入會登記、掃瞄音樂會門票以賺取積分、兌換獎賞，查看積分及會籍——更多功能將不日推出，敬請期待。 ",
        paragraph_4: "加入港樂會，全面投入精彩音樂體驗！",
        scan_earn_redeem: "掃門票．賺積分．換獎賞",
        simply_scan: "只需掃描、上載門票，便可輕鬆賺取積分，在個人會員的版面中一目了然！",
        earn_points_on : "憑港樂指定音樂會消費賺取積分，盡擁更多豐富優惠及禮遇。",
        catering_to_your  : "我們積極與多個優質品牌合作，推出多元化的獎賞及更靈活的兌換體驗，當中包括餐飲、零售、酒店消費優惠折扣、「亞洲萬里通」飛行里數計劃兌換等。",
        and_more: "更多精彩優惠",
        discounts: "非凡折扣",
        dedicated_hotline: "快人一步",
        priority: "最新消息",
        unique_experiences: "專屬熱線",
        latest_news: "難忘體驗",
        fabulous_benefits: "豐富優惠",
      },
      about_club_bravo_tier: {
        tier: "會籍",
        club_bravo_Black: "基本會員",
        club_bravo_Red: "紅卡會員",
        club_bravo_Gold: "金卡會員",
        available_point: "可用積分",
        accumulated_points_in_single_season: "於單一樂季賺取之積分",
        number_of_tickets_bought_in_single_season: "於單一樂季購買之門票數量",
        number_of_programmes_bought_in_single_season: "於單一樂季購買之節目數量",
        member_privileges: "會員福利",
        five_hundred_welcome_offer_title: '<span class="cb-logo">b</span>500迎新積分',
        five_hundred_welcome_offer_content: '只需填妥會員個人資料，即可獲贈<span class="cb-logo">b</span>500迎新積分！',
        member_exclusive_watch_and_listen_performances_title: '「賞樂聽樂」會員專屬精選演出',
        member_exclusive_watch_and_listen_performances_content: "免費欣賞港樂過往的會員專屬精選完整演出影片。不論是錯過了合家歡音樂會或年度的太古「港樂．星夜．交響曲」，抑或想重溫交響曲及協奏曲演奏，都可找到最適合當刻欣賞的美樂。",
        all_club_bravo_black_benefits_plus: "基本會員之所有福利，加上：",
        thousand_upgrade_bonus_for_club_bravo_red_title: '<span class="cb-logo">b</span>1000紅卡會員升級獎賞',
        thousand_upgrade_bonus_for_club_bravo_red_content: '一旦成為紅卡會員，立即賺取額外<span class="cb-logo">b</span>1000！',
        all_club_bravo_red_benefits_plus: "紅卡會員之所有福利，加上：",
        two_thousand_upgrade_bonus_for_club_bravo_gold_title: '<span class="cb-logo">b</span>2000金卡會員升級獎賞',
        two_thousand_upgrade_bonus_for_club_bravo_gold_content: '一旦成為金卡會員，立即賺取額外<span class="cb-logo">b</span>2000！',
        complete_profile_reminder: "請完成您的個人資料以啟動您的Club Bravo會員資格。"
      },
      registration_membership_options: {
        join_club_bravo_title: "加入港樂會",
        join_club_bravo_description: "港樂會會員可憑港樂音樂會門票消費賺取積分，兌換專屬獎賞，享受獨家福利。立即加入！",
        free_membership_title: "免費會員",
        free_membership_content:'立即登記為免費基本會員，費用全免！填妥所有資料，更可享有<span cb-logo">b</span>500迎新積分（相等於<span class="asiamile-logo">a</span>125「亞洲萬里通」里數）！',
        free_upgrade_to_higher_tiers: "免費升級到更高等級",
        paid_membership_title: "紅卡會員",
        paid_membership_content: "急不及待想享有更多優惠？只需繳付$300年費，即可升級至紅卡會員，享受門票八五折、免費音樂會優先登記等尊尚待遇！",
        current_members_title: "港樂會會員",
        current_members_content: "於2022年或之前加入港樂會之會員，免費登入我們預先為您準備好的紅卡或金卡帳戶，即可享受已有（和更多！）獎賞。",
        standard_chartered_cathay_mastercard_holders_title: "渣打國泰 Mastercard持有人",
        standard_chartered_cathay_mastercard_holders_content: "渣打國泰 Mastercard持有人現可免費升級會員種類，機會難逢！",

        existing_member_title: "現有港樂會會員",
        existing_applied_before_date: "（會籍有效期至2024年8月31日）",
        existing_member_intro: "免費啟用我們預先為您準備好的紅卡或金卡會員帳戶！",
        activate_your_account: "立即登記",

        free_member_title: "基本會員免費登記",
        free_member_intro: "立即展開您的港樂會獎賞之旅，費用全免！",
        register_for_free: "立即登記",

        paid_member_title: "即時成為紅卡會員",
        paid_member_intro: "繳付$300登記為港樂紅卡會員，享受基本八五折門票優惠等尊尚禮遇！</br><b>2023 太古「港樂・星夜・交響曲 」會員優先登記只適用於2023年10月17日或之前成為港樂會紅卡或金卡會員。</b>",
        paid_applied_before_date: "(會籍有效期至2023年8月31日)",
      },
      registration_auth0: {
        register_for_new_club_bravo_account: "開啟「港樂會」新帳戶",
        register_for_new_club_bravo_content: "請輸入您的電郵地址及設定密碼，以開啟「港樂會」新帳戶",
        enter_your_email: "您的電郵地址",
        set_up_your_password: "設定帳戶密碼",
        confirm_your_password: "確認帳戶密碼",
        i_would_like_to_subscribe_to_e_news_from_hong_kong_philharmonic_orchestra:"我希望經電郵收到香港管弦樂團的最新資訊",
        register: "建立帳戶",
      },
      registration_existing_step_zero: { // for existing member
        current_members_title: "現有會員",
        current_members_content_1: "於2022年或之前加入港樂會之會員，可在此免費登入我們預先為您準備好的紅卡或金卡帳戶。",
        current_members_content_2: "我們於早前已透過郵寄及電郵寄出您的會員號碼及免費升級代碼。請於以下表格填妥資料，開始您的會籍免費升級之旅，享受已有（和更多！）獎賞。",
        enjoy_your_deserved_and_more_benefits: "享受您應得的（以及更多！）福利！",
        club_bravo_membership_number_with_example: "您的港樂會會員編號",
        your_last_name_with_example: "您的英文姓氏",
        free_upgrade_code_with_example: "免費升級代碼（如asfaos8d21308yv213!~）",
        create_upgraded_membership_account_for_free: "免費開啟升級會籍",
      },
      registration_sc_form: {
        registration: '渣打國泰Mastercard客戶',
        left_title: '歡迎加入/升級港樂會會籍！',
        left_content:
            '渣打國泰Mastercard客戶不但可透過購買香港管弦樂團門票賺取「亞洲萬里通」里數，更可獲享免費港樂會會籍升級，尊享門票折扣優惠和優先登記免費音樂會門票！<br><br>' +
            '如您尚未成為港樂會會員，請點選「立即登記」以註冊帳戶; 如您已是現有的港樂會會員，請先「登入」以作免費升級！',
        token_left_content:
            '渣打國泰Mastercard客戶不但可透過購買香港管弦樂團門票賺取「亞洲萬里通」里數，更可獲享免費港樂會會籍升級，尊享門票折扣優惠和優先登記免費音樂會門票！<br><br>' +
            '加入/升級港樂會會籍步驟如下：<br><br>' +
            '1. 請註冊/登入您的港樂會帳戶<br><br>' +
            '2. 前往「國泰購物」所發送的兌換獎勵確認電郵，並點擊 「兌換」<br><br>' +
            '3. 根據指示完成註冊以便升級港樂會會籍',
        right_title: "港樂會會員專享禮遇",
        right_content: "您將可享受正價門票85折優惠、免費音樂會優先登記等尊尚禮遇！",
        footnote: "*港樂會賬戶一經註冊/升級則不能轉讓至其他港樂會帳戶",
      },
      registration_step_three: { // for free, paid and existing member
        i_would_like_to_receive_email_promotion: "我想收到電子郵件促銷",
        do_not_wish_receive_promotion: "如閣下不欲收到樂團最新的活動和推廣資訊，請於方格填上剔號",
        i_confirm_email_correct: '* 我確認我的電郵地址正確：',
        i_agree_to_the_hk_phil_1: "* 我同意香港管弦樂團之",
        privacy_policy: "私隱政策",
        i_agree_to_the_hk_phil_2: " 及 ",
        terms_of_conditions : "條款及細則",
        complete_all_items_in_the_form_for_free_welcome_offer_title: "正價門票優惠低至7折",
        complete_all_items_in_the_form_for_free_welcome_offer_content: "紅卡會員於城市售票網購買指定港樂音樂會正價門票，可獲低至7折優惠，不設最低消費！更多資料",
      },
      registration_success: {
        scan_ticket: "掃描門票",
        view_rewards: "瀏覽獎賞",
      },
      registration_paid_failed: { // for paid member
        restart_membership_application: "重新嘗試",
      },
      registration_common: {
        join_club_bravo: "加入港樂會",
        free_member_registration: "免費會員",
        red_member_registration: "會員註冊",
        club_bravo_red_member_registration: "紅卡會員",
        registration: "建立帳戶",
        register: "建立帳戶",
        about_you: "關於您",
        contact_details: "聯絡資料",
        your_preference: "喜好",
        required_field: "* 必填項目",
        previous_step: "上一步",
        next_step: "下一步",
        submit: "遞交",
        submit_and_pay: "遞交及付款",
        free_member_intro_title: "免費會籍",
        free_member_intro_1: "基本會員免費登記",
        free_member_intro_2: "立即展開您的港樂會獎賞之旅，費用全免！",
        register_for_free: "立即登記",
        existing_member_intro_1: "現有港樂會會員（2023年1月或之前已申請）",
        existing_member_intro_2: "免費啟用我們預先為您準備好的紅卡或金卡會員帳戶！",
        activate_your_account: "立即登記",
        paid_member_intro_title: "付費會籍",
        paid_member_intro_1: "即時成為紅卡會員",
        paid_member_intro_2: "繳付$300登記為港樂紅卡會員，享受基本八五折門票優惠等尊尚禮遇！（會籍有效期至2024年8月31日）",
        register_now: "立即登記",
        asia_miles_conversion_content: '以港樂會積分輕鬆轉換「亞洲萬里通」里數，每<span class="cb-logo">b</span>4 就可兌換<span class="asiamile-logo">a</span>1里數，超值！',
        title: '成為我們的港樂會會員',
        subtitle: '請選擇您的會員類型。',
        migrate_description: '指定港樂會會員可免費兌換升級後的港樂會紅卡/金卡會員資格。 請在下方輸入您的會員資料以繼續。',
        migrate_description_title: '免費升級以後',
        migrate_description_content: '您將可享受基本八五折門票優惠、免費音樂會優先登記、樂季門票優先預訂等尊尚禮遇！',
        tier_selection: '會員類型',

        // YA form
        ya_registration_title: "[TC] BE A YOUNG AUDIENCE SCHEME MEMBER",
        ya_registration_subtitle: "[TC] MEMBER REGISTRATION",
        school_details_and_your_preference: "[TC] School Details + Your preference",
        ensure_correct_student_info: '[TC] Please make sure the information below is identical with your student ID card',
      },
      registration_message: {
        required_field: "* 必填項目",
        invalid_first_name: "無效的英文名字!",
        invalid_last_name: "無效的英文姓氏!",
        invalid_chinese_name: "無效的中文名稱！請確保您只輸入了中文單詞",
        invalid_email: "無效的電子郵件地址!",
        invalid_mobile: "無效手機!",
        submit_failed: "提交失敗! ",
        salutation_invalid: "無效的稱呼!",
        first_name_missing: "缺少英文名字!",
        first_name_invalid: "無效英文名字!",
        last_name_missing: "'缺少英文姓氏!",
        last_name_invalid: "無效英文姓氏!",
        dob_missing: "缺少出生日期!",
        dob_invalid: "無效出生日期!",
        gender_invalid: "無效性別!",
        email_missing: "缺少電子郵件地址!",
        email_has_been_used: "此電子郵件地址已經被使用!",
        email_invalid: "無效電子郵件!",
        mobile_missing: "缺少電話號碼!",
        mobile_has_been_used: "此電話號碼已經被使用!",
        mobile_invalid: "無效電話號碼!",
        prefer_lang_invalid: "無效首選語言!",
        receive_email_promotion_invalid: "接收電子郵件推廣設置無效!",
        consent_invalid: "同意設置無效!",
        migrate_successful: "原有會員帳戶資料已遷移",
      },
      sign_in: {
        // for auth0
        sign_in_to_your_club_bravo_account_title: "登入「港樂會」帳戶",
        sign_in_to_your_club_bravo_account_content: "請輸入您的電郵地址及密碼，以登入「港樂會」帳戶",
        your_email_address: "電郵地址",
        your_password: "帳戶密碼",
        sign_in: "登入",
        forget_password: "忘記密碼？",
        not_yet_joined_club_bravo: "尚未加入港樂會？建立帳戶",
        register_now: "立即注册",
        // for Forget Password
        forget_your_password_title: "忘記您的密碼？",
        forget_your_password_content: "請提供您在港樂會會籍中使用之電郵地址。您將經此電郵地址收到更改密碼之連結。",
        enter_your_email_address: "輸入您的電郵地址",
        back: "回上頁",
        submit: "提交",
        // email_template_tbc: "Email template TBC",
        access_expiry: "訪問過期",
        authentication_error_title: "認證失敗",
        authentication_error_message: "您的認證連結已過期，請重新更改密碼。",
      },
      landing_page: {
        membership_valid_until: "會員有效期至：",
        until_next_level: "距離下一會員種類",
        tickets: "門票數量",
        programs: "節目數量",
        point: "賺取積分",
        membership: "會籍",
        membership_status: "會員種類",
        club_bravo_Black : "基本會員",
        club_bravo_Red: "紅卡會員",
        club_bravo_Gold: "金卡會員",
        expiry_date: "有效期至",
        renewal_remarks: "將於每年9月1日自動更新續期",
        redeem_rewards: "換取獎賞",
        asia_miles: "立即兌換「亞洲萬里通」里數！",
        convert_to_asia_miles: "兌換「亞洲萬里通」里數",
        view_personal_details : "詳細資料",
        personal_details : "個人資料",
        please_fill_out: "請填寫以下信息以開始您的會員資格。",
        edit: "編輯",
        edit_profile: "會員資料",
        account_details: "帳戶資料",
        account_number: "帳戶號碼",
        salutations: "稱呼",
        prof: "教授",
        select: "選擇",
        dr: "博士",
        mr: "先生",
        mrs: "女士",
        ms: "小姐",
        name: "英文姓名",
        first_name: "英文名字",
        last_name: "英文姓氏",
        chinese_name: "中文姓名",
        date_of_birth: "出生日期",
        gender:"性別",
        male: "男",
        female: "女",
        other: "其他",
        email: "電郵地址",
        email_address: "電郵地址",
        phone: "電話",
        address: "地址",
        country_code: "國家/地區代碼",
        mobile: "電話",
        flat_or_unit: "單位/室",
        floor: "樓層",
        block_or_phase: "座數/期數",
        house_or_building_estate:"屋苑/屋邨",
        road_or_street: "街道", // TODO street_or_road: "街道", // check
        district: "區域",
        city: "城市",
        country: "國家/地區",
        your_interest: "您的興趣",
        area_of_interest: "興趣",
        symphonic_works: "交響曲",
        concerto: "協奏曲",
        chamber_music: "室內樂",
        pop_music: "流行音樂",
        preferred_language: "偏好語言",
        english: "英文",
        chinese: "中文",
        consent_for_receiving_email: "不同意接收電子郵件",
        yes: "是",
        no: "否",
        upgrade_membership: "升級會籍",
        renew: "續會",
        membership_card: "顯示電子會員卡",
        complete_profile_rewards: '填妥個人資料，即享<span class="cb-logo">b</span>500迎新優惠（相當於<span class="asiamile-logo">a</span>125里數！） ',
        consent_setting: "同意設置",
        receive_email_promotion_setting: "接收電子郵件促銷設置",
        email_or_mobile: "電郵/電話",
        back_to_member_detail: '回到我的帳戶',
        missing_membership_card_hint: '未找到會員卡，請聯繫我們的客服。',
        complete_profile_to_upgrade: '填妥個人資料，即時升級會籍',
        email_or_mobile_placeholder: "請參閱啟用帳戶信件或電郵填寫",
        full: "已達成",
        manage_membership: "管理會籍",

        // YA form
        guardian_name: "[TC] Guardian's Name",
        guardian_mobile: "[TC] Guardian's Mobile",
        guardian_email: "[TC] Guardian's Email",
        school_name: "[TC] School Name",
        school_type: "[TC] School Type",
        student_card_file: "[TC] Student ID",

        // Member detail page
        sc_upgrade_offer: "渣打國泰萬事達卡持卡人",
        sc_upgrade_offer_btn_upgrade_now: "免費升級",
        sc_upgrade_offer_btn_details: "詳細資料",
        sc_upgrade_offer_btn_tnc: "條款和細則",
      },
      change_password: {
        change_of_password: "更改密碼",
        basic_information: "基本資料",
        please_enter_a_new_password_for_your_club_bravo_account: "請為您的港樂會帳戶設定新密碼。",
        your_new_password: "新密碼",
        confirm_your_new_password: "確認新密碼",
      },
      point_transaction_log: {
        point_transaction_log: "積分誌賬",
        view_point_transaction_log: "查看積分誌賬",
        date: "日期",
        description: "項目",
        point_change: "積分變更",
        balance: "積分結餘",
        profile_completion: "填妥個人資料",
        ticket_scanned: "掃描門票",
        ticket_scanned_with_bonus: "掃描門票（連獎勵積分）",
        reward_redemption: "兌換獎賞",
        asia_miles_redemption: "兌換「亞洲萬里通」里數",
        bulk_import: "系統掃描門票 (23/24 樂季預訂)",
        return_refund_exchange: "扣除積分 (退票/退款/換票)",
        christmas_birthday_reward_2023: "2023 聖誕賞「樂」雙倍積分",
        birthday_double_points: "生日雙倍分數",
        season_subscription_points: "2324樂季預訂積分"
      },
      scan_tutorial: {
        start_now: "馬上開始",
        tips_and_steps: "提示和步驟",
      },
      scan_your_ticket: {
        ref_number: "參考編號：",
        concert_name: "音樂會名稱：",
        performance_date: "演出日期：",
        performance_time: "演出時間：",
        seat_number: "座位編號：",
        price: "票價：",
        success_cb_point_reward: "賺取之港樂會積分:",
        fail_ticket_submission_will_not_be_counted_message: "是次門票登記將不能賺取港樂會積分。請檢查門票並將正確門票重新上載。",
        enjoy_the_concert: "期待下次在音樂會見到您！",
        scanner_hint: "請在方框內掃描",
        upload_photo: "上傳照片",
        start_scanning: "開始掃描",
        stop_scanning: "停止掃描",
        upload_photo_hint: "請掃描或上傳您的門票",
        upload_photo_reminder: "無法掃描門票? 試試上傳照片!",
        invalid_qrcode: "二維碼無效",
        scan_another: '掃描另一張票',
        ticket: '門票',
        tnc: '條款與細則',
        login_before_scan: '掃描前請先登入',
        scan_hint: '請確保您的電子郵件已通過驗證',
        pending_and_invalid: '如欲提交門票資料，請點擊「確定」以便繼續進行認證。如欲提交另一場門票資料，請點擊「取消」以重新提交。'
      },
      offer_convert_to_miles: {
        points_to_convert: "兌換至里數之港樂會積分",
        asia_miles_intro: '以港樂會積分輕鬆兌換「亞洲萬里通」里數，每<span class="cb-logo">b</span>{{ rate }} 就可兌換<span class="asiamile-logo">a</span>1里數！',
        conversion_rate: '兌換率：<span class="cb-logo">b</span>{{ rate }} = <span class="asiamile-logo">a</span>1里',
        suggest_points_to_convert: "建議兌換至里數之港樂會積分",
        asia_miles_to_be_converted: "兌換里數：",
        miles: "里",
        points: "分",
        available_point_before_conversion: "兌換前之港樂會積分結餘：",
        available_point_after_conversion: "兌換後之港樂會積分結餘：",
        your_asia_miles_member_first_name: "您的國泰會員名稱名字：",
        your_asia_miles_member_last_name: "您的國泰會員名稱姓氏：",
        your_asia_miles_membership_number: "您的國泰會員號碼：",
        confirm_asia_miles_account_correct: "我確認我的國泰會員名稱及號碼正確",
        understand_the_conversion_is_non_changeable_and_non_reversible: "我明白港樂會積分兌換至「亞洲萬里通」里數之交易申請不可更改或逆轉",
        i_agree_to_the: "我接受",
        term_and_condition: "條款及細則",
        redeem_now: "立即兌換",
        come_back_with_more_cb_points: "累積更多港樂會積分以兌換！",
        redeem__confirm_checkbox: "您確認兌換嗎？",
        sold_out: "售罄",
        back_to_offers: "瀏覽其他獎賞",
        asia_miles_convert_ref_no: "參考編號：",
        asia_miles_name: "亞洲萬里通賬戶名稱：",
        asia_miles_no: "亞洲萬里通賬戶號碼：",
        asia_miles_converted: "轉換的亞洲萬里通里數：",
        point_to_convert: "轉換中使用的港樂會積分：",
        remaining_points: "轉換後可用的港樂會積分：",
      },
      month: {
        1: '一月',
        2: '二月',
        3: '三月',
        4: '四月',
        5: '五月',
        6: '六月',
        7: '七月',
        8: '八月',
        9: '九月',
        10: '十月',
        11: '十一月',
        12: '十二月',
      },
      country: {
        hong_kong_sar: '香港',
        taiwan: '台灣',
        china: '中國',
      },
      index: "首頁",
      button: {
        close: '關閉',
        submit: '提交',
        save: '儲存',
        confirm: '確定',
        retake: '取消',
        login: '登入',
        logout: '登出',
        back: '返回',
        edit: '編輯',
        verify_later: '稍後驗證',
        start_now: "馬上開始",
        go_to_profile: "前往個人資料",
      },
      error: {
        validation_error: '驗證錯誤',
        invalid_point_conversion: '如要轉換積分成里數，該港樂會積分數值需可被 4 整除。請重新輸入港樂會積分，謝謝！',
        required: '請填寫{{field}}',
        invalid: '無效{{field}}',
        missing: '未填寫{{field}}',
        already_been_used: '該{{field}}已被使用',
        min_points_not_reached: '如要轉換積分成里數，需要至少轉換 1000 港樂會積分。請重新輸入港樂會積分，謝謝！',
        missing_tnc: '請閱讀條款和條件並選中以下複選框',
        confirmedEmailIsCorrect: '請確認電子郵件正確並選中以下複選框',
        invalid_asia_miles: '無效的「亞洲萬里通」兌換',
        incorrect_account_data: '連結帳戶的資料錯誤',
        profile_migrate_failure: '保存Profile Is Migrated失敗!',
        not_enough_points: '您尚未有充足的港樂會積分兌換此獎賞',
        invalid_tier: '您現時的帳戶為金卡港樂會會籍，故未能兌換「港樂會紅卡會籍」，請檢查您所登入的帳戶是否正確。如有任何疑問，歡迎電郵至bravo@hkphil.org查詢。',
        duplicate_token: '此港樂會會籍已被兌換。請前往「國泰購物」以兌換另一個港樂會會籍。謝謝！',
      },
      events: {
        event_name: '事件',
        tickets: '張門票',
        registration_coming_soon: '即將報名',
        popup_confirm_title: '確認註冊',
        popup_confirm_message: '請確認您的活動註冊',
        event_quota_full: '活動名額已滿。',
        user_quota_full: '用戶名額已滿。',
        successful_registration: '註冊成功。',
        waiting_list: '已成功添加至等待名單。',

      },
      scanner: {
        verify_email: '請驗證郵箱',
      },
      verify_email: {
        title: '驗證您的電子郵件',
        content: '驗證郵件已發送。請在註冊前檢查您的郵箱以驗證電子郵件。',
        resend: '重新發送驗證郵件',
        reload: '重新加載',
        reload_hint: '如果您已經驗證了您的電子郵件，請重新加載網頁',
        popup_title: '驗證電郵已重新發出',
        popup_message: '請檢查您的電子郵箱，並按指示確認電郵地址。',
      },
      model_constants: {
        MemberProfile: {
          SCHOOL_TYPES: {
            kindergarten: '[TC] Kindergarten',
            primary_school: '[TC] Primary School',
            secondary_school: '[TC] Secondary School',
            post_secondary: '[TC] Post-secondary',
          }
        },
      },
    },
  },
  sc: {
    translation: {
      lang: {
        en: '英文',
        tc: '中文', 
        sc: '中文'
      },
      html_title: {
        asiamiles: '兑换「亚洲万里通」里数',
        asiapay_error: '付款失败',
        asiapay_fail: '付款失败',
        contact_us: '联络我们',
        how_to: '教学',
        home: '港乐会 － 香港管弦乐团',
        login: '登入',
        member_detail: '我的帐户',
        member_detail_edit: '编辑个人资料',
        not_found: '未能找到页面',
        personal_detail: '个人资料',
        point_transaction_log: '积分志账',
        redeem_fail: '兑换奖赏失败',
        redeem_success: '兑换奖赏成功',
        registration: '会员登记',
        registration_existing: '现有港乐会会员登记',
        registration_free: '基本会员免费登记',
        registration_red: '即时成为红卡会员',
        registration_ya: '[SC] Be a Young Audience Scheme Member',
        registration_success: '会员登记成功',
        reward_detail: ' | 奖赏详情',
        reward_list: '换取奖赏',
        scan_failed: '门票扫描不成功',
        scan_intro: '扫描门票',
        scanner: '扫描门票',
        scan_preview: '预览扫描门票资料',
        scan_success: '门票扫描成功',
        terms_and_conditions: '条款及细则',
        what_is_club_bravo: '全新港乐会',
      },
      innerHtml: {
        cb_logo: '<span class="cb-logo">b</span>',
        cb_logo_white: '<span class="cb-logo-white">b</span>',
      },
      user: {
        personal_data: "个人资料",
        sign_up: "登入 / 登记",
        login_to_continue: "请登入以继续",
        logout: "登出",
        welcome_back: "欢迎回来",
      },
      header: {
        home: "主页",
        what_is_club_bravo: "关于港乐会",
        offers: "奖赏",
        scan_your_ticket: "扫描门票",
        how_to: "教学",
        my_account: "我的帐户",
        membership_tiers: '会员种类',
        show_card: '出示卡片'
      },
      footer: {
        terms_and_conditions: "条款与细则",
        disclaimer: "免责条款",
        privacy_policy: "私隐政策",
        hk_phil_main_website: "港乐主网页",
        copyright: "© {{year}} 香港管弦乐团 版权所有 香港管弦乐团由香港特别行政区政府资助，亦为香港文化中心场地伙伴。",
        supports: "",
        swire: "首席赞助：太古集团",
      },
      homepage: {
        scan_earn_redeem: "扫门票．赚积分．换奖赏",
        experience_new_cb: "立即体验全新港乐会，将每张已购买的门票化为积分，换取里数、礼券及奖励门票等礼遇！",
        join_for_free: "免费加入",
        highlight_rewards: "精选礼遇",
        what_is_club_bravo: "关于港乐会",
        learn_more: "查看更多",
        point_balance: "积分结余",
      },
      rewards: {
        redeem: "奖赏",
        special_offer: "特别优惠",
        all_year_round: "全年奖赏",
        arts_and_culture: '商户优惠－消闲娱乐',
        bookstore_and_cultural_institution: '商户优惠－书店及文化机构',
        lifestyle: '商户优惠－精品及消闲娱乐',
        music_centre_and_institution: '商户优惠－琴行及音乐机',
        restaurant: '商户优惠－食肆',
        event: '事件',
        back_to_rewards: "回到奖赏",
        description: "详细资料",
        remarks: "备注",
        terms_and_conditions: "条款及细则",
        quantity: "数量",
        please_verify_email: '请先验证的电子邮件',
        item_not_found: '出现错误！没有此礼品/礼遇',
        popup_confirm_title: '您确认要兑换此礼品/礼遇吗？',
        popup_confirm_message: '请确认您兑换的奖赏',
        popup_retry_title: '兑换失败',
        popup_retry_message: '只剩余{{ quantity }}件，请调整数量后再试。',
        sold_out_title: '售罄',
        sold_out_message: '此礼品/礼遇已售罄了',
        redeem_ref_no: '参考编号：',
        redeem_gift_name: '礼品/礼遇名称：',
        points_redeemed: '使用之港乐会积分：',
        remaining_points: '港乐会积分结余：',
        mailing_address: '邮寄地址：',
        coupon: 'Your Coupon(s):  ',
        add_another_redeem: '继续兑换其他礼品/礼遇',
        back_to_reward_list: '回到礼遇一览',
        shipping_to: '邮寄地址',
        delivery_option: 'Delivery: ',
        delivery: 'Delivery',
        on_site_pick_up: 'On-site pick-up',
        email: 'Email',
        please_enter_address: '请填写邮寄地址',
        reward_name: '奖赏',
        please_accept_tnc: '接受条款和条件以兑换',
        agree_tnc: '我同意兑换此奖赏之条款及细则'
      },
      about_club_bravo_general: {
        title: "关于港乐会",
        paragraph_1: "港乐会为香港管弦乐团（港乐）的会员计划。自2000年成立至今，一直秉承作为港乐与乐迷之间的桥梁，且于2022年革新，为会员提供焕然一新的体验。入会后，可凭港乐音乐会门票消费赚取积分，兑换专属奖赏，享受独家福利。会籍采用分级制，设有黑级、红级及金级——赚取指定积分数值即可升级，尽拥更多丰富优惠及礼遇。",
        paragraph_2: "为拉近与会员之间的距离，提供更个人化的互动服务，港乐会为会员度身订造多项专属礼遇：生日礼遇、门票折扣优惠、优先登记音乐会及活动、乐季优先订票、「赏乐听乐」影片观看权限、港乐电子通讯、会员服务热线等。",
        paragraph_3: "我们致力为会员打造更方便轻松的体验，推出全新设计的网站。一站式服务涵盖入会登记、扫瞄音乐会门票以赚取积分、兑换奖赏，查看积分及会籍——更多功能将不日推出，敬请期待。",
        paragraph_4: "加入港乐会，全面投入精彩音乐体验！",
        scan_earn_redeem: "扫门票．赚积分．换奖赏",
        simply_scan: "只需扫描、上载门票，便可轻松赚取积分，在个人会员的版面中一目了然！",
        earn_points_on : "凭港乐指定音乐会消费赚取积分，尽拥更多丰富优惠及礼遇。",
        catering_to_your  : "我们积极与多个优质品牌合作，推出多元化的奖赏及更灵活的兑换体验，当中包括餐饮、零售、酒店消费优惠折扣、「亚洲万里通」飞行里数计划兑换等。",
        and_more: "更多精彩优惠",
        discounts: "非凡折扣",
        dedicated_hotline: "快人一步",
        priority: "最新消息",
        unique_experiences: "专属热线",
        latest_news: "难忘体验",
        fabulous_benefits: "丰富优惠",
      },
      about_club_bravo_tier: {
        tier: "会籍",
        club_bravo_Black: "基本会员",
        club_bravo_Red: "红卡会员",
        club_bravo_Gold: "金卡会员",
        available_point: "可用积分",
        accumulated_points_in_single_season: "于单一乐季赚取之积分",
        number_of_tickets_bought_in_single_season: "于单一乐季购买之门票数量",
        number_of_programmes_bought_in_single_season: "于单一乐季购买之节目数量",
        member_privileges: "会员福利",
        five_hundred_welcome_offer_title: '<span class="cb-logo">b</span>500迎新积分',
        five_hundred_welcome_offer_content: '只需填妥会员个人资料，即可获赠<span class="cb-logo">b</span>500迎新积分！',
        member_exclusive_watch_and_listen_performances_title: '「赏乐听乐」会员专属精选演出',
        member_exclusive_watch_and_listen_performances_content: "免费欣赏港乐过往的会员专属精选完整演出影片。不论是错过了合家欢音乐会或年度的太古「港乐．星夜．交响曲」，抑或想重温交响曲及协奏曲演奏，都可找到最适合当刻欣赏的美乐。",
        all_club_bravo_black_benefits_plus: "基本会员之所有福利，加上：",
        thousand_upgrade_bonus_for_club_bravo_red_title: '<span class="cb-logo">b</span>1000红卡会员升级奖赏',
        thousand_upgrade_bonus_for_club_bravo_red_content: '一旦成为红卡会员，立即赚取额外<span class="cb-logo">b</span>1000！',
        all_club_bravo_red_benefits_plus: "红卡会员之所有福利，加上：",
        two_thousand_upgrade_bonus_for_club_bravo_gold_title: '<span class="cb-logo">b</span>2000金卡会员升级奖赏',
        two_thousand_upgrade_bonus_for_club_bravo_gold_content: '一旦成为金卡会员，立即赚取额外<span class="cb-logo">b</span>2000！',
        complete_profile_reminder: "请完成您的个人资料以启动您的Club Bravo会员资格。"
      },
      events: {
        event_name: '事件',
        tickets: '张门票',
        registration_coming_soon: 'Registration coming soon',
        popup_confirm_title: '即将报名',
        popup_confirm_message: '请确认您的活动注册',
        event_quota_full: '活动名额已满。',
        user_quota_full: '用户名额已满。',
        successful_registration: '注册成功。',
        waiting_list: '已成功添加至等待名单。',

      },
      registration_membership_options: {
        join_club_bravo_title: "加入港乐会",
        join_club_bravo_description: "港乐会会员可凭港乐音乐会门票消费赚取积分，兑换专属奖赏，享受独家福利。立即加入！",
        free_membership_title: "免费会员",
        free_membership_content: '立即登记为免费基本会员，费用全免！填妥所有资料，更可享有<span class="cb-logo">b</span>500迎新积分（相等于<span class="asiamile-logo">a</span>125「亚洲万里通」里数）！',
        free_upgrade_to_higher_tiers: "免费升级到更高等级",
        paid_membership_title: "红卡会员",
        paid_membership_content: "急不及待想享有更多优惠？只需缴付$300年费，即可升级至红卡会员，享受门票八五折、免费音乐会优先登记等尊尚待遇！",
        current_members_title: "港乐会会员",
        current_members_content: "于2022年或之前加入港乐会之会员，免费登入我们预先为您准备好的红卡或金卡帐户，即可享受已有（和更多！）奖赏。",
        standard_chartered_cathay_mastercard_holders_title: "渣打国泰 Mastercard持有人",
        standard_chartered_cathay_mastercard_holders_content: "渣打国泰 Mastercard持有人现可免费升级会员种类，机会难逢！",

        existing_member_title: "现有港乐会会员",
        existing_applied_before_date: "（会籍有效期至2024年8月31日）",
        existing_member_intro: "免费启用我们预先为您准备好的红卡或金卡会员帐户！",
        activate_your_account: "立即登记",

        free_member_title: "基本会员免费登记",
        free_member_intro: "立即展开您的港乐会奖赏之旅，费用全免！",
        register_for_free: "立即登记",

        paid_member_title: "即时成为红卡会员",
        paid_member_intro: "缴付$300登记为港乐红卡会员，享受基本八五折门票优惠等尊尚礼遇！<br/><b>2023 太古「港乐・星夜・交响曲 」会员优先登记只适用於2023年10月17日或之前成为港乐会红卡或金卡会员。</b>",
        paid_applied_before_date: "(会籍有效期至2023年8月31日)",
      },
      registration_auth0: {
        register_for_new_club_bravo_account: "开启「港乐会」新帐户",
        register_for_new_club_bravo_content: "请输入您的电邮地址及设定密码，以开启「港乐会」新帐户",
        enter_your_email: "您的电邮地址",
        set_up_your_password: "设定帐户密码",
        confirm_your_password: "确认帐户密码",
        i_would_like_to_subscribe_to_e_news_from_hong_kong_philharmonic_orchestra: "我希望经电邮收到香港管弦乐团的最新资讯",
        register: "建立帐户",
      },
      registration_existing_step_zero: { // for existing member
        current_members_title: "现有会员",
        current_members_content_1: "于2022年或之前加入港乐会之会员，可在此免费登入我们预先为您准备好的红卡或金卡帐户。",
        current_members_content_2: "我们于早前已透过邮寄及电邮寄出您的会员号码及免费升级代码。请于以下表格填妥资料，开始您的会籍免费升级之旅，享受已有（和更多！）奖赏。",
        enjoy_your_deserved_and_more_benefits: "享受您应得的（以及更多！）福利！",
        club_bravo_membership_number_with_example: "您的港乐会会员编号",
        your_last_name_with_example: "您的英文姓氏",
        free_upgrade_code_with_example: "免费升级代码（如asfaos8d21308yv213!~）",
        create_upgraded_membership_account_for_free: "免费开启升级会籍",
      },
      registration_sc_form: {
        registration: "渣打国泰Mastercard客户",
        left_title: '欢迎加入/升级港乐会会籍！',
        left_content:
            '渣打国泰Mastercard客户不但可透过购买香港管弦乐团门票赚取「亚洲万里通」里数，更可获享免费港乐会会籍升级，尊享门票折扣优惠和优先登记免费音乐会门票！<br><br>' +
            '如您尚未成为港乐会会员，请点选「立即登记」以注册帐户; 如您已是现有的港乐会会员，请先「登入」以作免费升级！',
        token_left_content:
            '渣打国泰Mastercard客户不但可透过购买香港管弦乐团门票赚取「亚洲万里通」里数，更可获享免费港乐会会籍升级，尊享门票折扣优惠和优先登记免费音乐会门票！<br><br>' +
            '加入/升级港乐会会籍步骤如下：<br><br>' +
            '1. 请注册/登入您的港乐会帐户<br><br>' +
            '2. 前往「国泰购物」所发送的兑换奖励确认电邮，并点击 「兑换」<br><br>' +
            '3. 根据指示完成注册以便升级港乐会会籍',
        right_title: "港乐会会员专享礼遇",
        right_content: "您将可享受正价门票85折优惠、免费音乐会优先登记等尊尚礼遇！",
        footnote: "*港乐会账户一经注册/升级则不能转让至其他港乐会帳戶",
      },
      registration_step_three: { // for free, paid and existing member
        i_would_like_to_receive_email_promotion: "我想收到电子邮件促销",
        do_not_wish_receive_promotion: "如阁下不欲收到乐团最新的活动和推广资讯，请于方格填上剔号",
        i_confirm_email_correct: '* 我确认我的电邮地址正确：',
        i_agree_to_the_hk_phil_1: "* 我同意香港管弦乐团之",
        privacy_policy: "私隐政策",
        i_agree_to_the_hk_phil_2: " 及 ",
        terms_of_conditions : "条款及细则",
        complete_all_items_in_the_form_for_free_welcome_offer_title: "正价门票优惠低至7折",
        complete_all_items_in_the_form_for_free_welcome_offer_content: "红卡会员于城市售票网购买指定港乐音乐会正价门票，可获低至7折优惠，不设最低消费！更多资料",
      },
      registration_success: {
        scan_ticket: "扫描门票",
        view_rewards: "浏览奖赏",
      },
      registration_paid_failed: { // for paid member
        restart_membership_application: "重新尝试",
      },
      registration_common: {
        join_club_bravo: "加入港乐会",
        free_member_registration: "免费会员",
        red_member_registration: "会员注册",
        club_bravo_red_member_registration: "红卡会员",
        registration: "建立帐户",
        register: "建立帐户",
        about_you: "关于您",
        contact_details: "联络资料",
        your_preference: "喜好",
        required_field: "* 必填项目",
        previous_step: "上一步",
        next_step: "下一步",
        submit: "递交",
        submit_and_pay: "递交及付款",
        free_member_intro_title: "免费会籍",
        free_member_intro_1: "只需填妥表格，即可登记为基本会员，填完即赚500分！简易方便，费用全免。",
        free_member_intro_2: "成功入会后，并凭港乐音乐会门票消费赚取积分，兑换专属奖赏，展开精彩礼遇之旅。",
        register_for_free: "立即登记",
        existing_member_intro_1: "基本会员免费登记",
        existing_member_intro_2: "立即展开您的港乐会奖赏之旅，费用全免！",
        activate_your_account: "立即登记",
        paid_member_intro_title: "付费会籍",
        paid_member_intro_1: "即时成为红卡会员",
        paid_member_intro_2: "缴付$300登记为港乐红卡会员，享受基本八五折门票优惠等尊尚礼遇！（会籍有效期至2024年8月31日）",
        register_now: "立即登记",
        asia_miles_conversion_content: '以港乐会积分轻松转换「亚洲万里通」里数，每<span class="cb-logo">b</span>4 就可兑换<span class="asiamile-logo">a</span>1里数，超值！',
        title: '成为我们的港乐会会员',
        subtitle: '请选择您的会员类型。',
        migrate_description: '指定港乐会会员可免费兑换升级後的港乐会红卡/金卡会员资格。 请在下方输入您的会员资料以继续。',
        migrate_description_title: '免费升级以后',
        migrate_description_content: '您将可享受基本八五折门票优惠、免费音乐会优先登记、乐季门票优先预订等尊尚礼遇！',
        tier_selection: '会员类型',

        // YA form
        ya_registration_title: "[SC] BE A YOUNG AUDIENCE SCHEME MEMBER",
        ya_registration_subtitle: "[SC] MEMBER REGISTRATION",
        school_details_and_your_preference: "[SC] School Details + Your preference",
        ensure_correct_student_info: '[SC] Please make sure the information below is identical with your student ID card',
      },
      registration_message: {
        required_field: "* 必填项目",
        invalid_first_name: "无效的英文名字!",
        invalid_last_name: "无效的英文姓氏!",
        invalid_chinese_name: "无效的中文名称！请确保您只输入了中文单词",
        invalid_email: "无效的电子邮件地址!",
        invalid_mobile: "无效手机!",
        submit_failed: "提交失败! ",
        salutation_invalid: "无效的称呼!",
        first_name_missing: "缺少英文名字!",
        first_name_invalid: "无效英文名字!",
        last_name_missing: "'缺少英文姓氏!",
        last_name_invalid: "无效英文姓氏!",
        dob_missing: "缺少出生日期!",
        dob_invalid: "无效出生日期!",
        gender_invalid: "无效性别!",
        email_missing: "缺少电子邮件地址!",
        email_has_been_used: "此电子邮件地址已经被使用!",
        email_invalid: "无效电子邮件!",
        mobile_missing: "缺少电话号码!",
        mobile_has_been_used: "此电话号码已经被使用!",
        mobile_invalid: "无效电话号码!",
        prefer_lang_invalid: "无效首选语言!",
        receive_email_promotion_invalid: "接收电子邮件推广设置无效!",
        consent_invalid: "同意设置无效!",
        migrate_successful: "原有会员帐户资料已迁移",
      },
      sign_in: {
        // for auth0
        sign_in_to_your_club_bravo_account_title: "登入「港乐会」帐户",
        sign_in_to_your_club_bravo_account_content: "请输入您的电邮地址及密码，以登入「港乐会」帐户",
        your_email_address: "电邮地址",
        your_password: "帐户密码",
        sign_in: "登入",
        forget_password: "忘记密码？",
        not_yet_joined_club_bravo: "尚未加入港乐会？建立帐户",
        register_now: "立即注册",
        // for Forget Password
        forget_your_password_title: "忘记您的密码？",
        forget_your_password_content: "请提供您在港乐会会籍中使用之电邮地址。您将经此电邮地址收到更改密码之连结。",
        enter_your_email_address: "输入您的电邮地址",
        back: "回上页",
        submit: "提交",
        // email_template_tbc: "Email template TBC",
        access_expiry: "访问过期",
        authentication_error_title: "认证失败",
        authentication_error_message: "您的认证连结已过期，请重新更改密码。",
      },
      landing_page: {
        membership_valid_until: "会员有效期至：",
        until_next_level: "距离下一会员种类",
        tickets: "门票数量",
        programs: "节目数量",
        point: "赚取积分",
        membership: "会籍",
        membership_status: "会员种类",
        club_bravo_Black : "基本会员",
        club_bravo_Red: "红卡会员",
        club_bravo_Gold: "金卡会员",
        expiry_date: "有效期至",
        renewal_remarks: "将於每年9月1日自动更新续期",
        redeem_rewards: "换取奖赏",
        asia_miles: "立即兑换「亚洲万里通」里数！",
        convert_to_asia_miles: "兑换「亚洲万里通」里数",
        view_personal_details : "详细资料",
        personal_details : "个人资料",
        please_fill_out: "请填写以下信息以开始您的会员资格。",
        edit: "编辑",
        edit_profile: "会员资料",
        account_details: "帐户资料",
        account_number: "帐户号码",
        salutations: "称呼",
        prof: "教授",
        select: "选择",
        dr: "博士",
        mr: "先生",
        mrs: "女士",
        ms: "小姐",
        name: "英文姓名",
        first_name: "英文名字",
        last_name: "英文姓氏",
        chinese_name: "中文姓名（如有）",
        date_of_birth: "出生日期",
        gender:"性别",
        male: "男",
        female: "女",
        other: "其他",
        email: "电邮地址",
        email_address: "电邮地址",
        phone: "电话",
        address: "地址",
        country_code: "国家/地区代码",
        mobile: "电话",
        flat_or_unit: "单位/室",
        floor: "楼层",
        block_or_phase: "座数/期数",
        house_or_building_estate:"屋苑/屋村",
        road_or_street: "街道", // TODO street_or_road: "街道", // check
        district: "区域",
        city: "城市",
        country: "国家/地区",
        your_interest: "您的兴趣",
        area_of_interest: "兴趣",
        symphonic_works: "交响曲",
        concerto: "协奏曲",
        chamber_music: "室内乐",
        pop_music: "流行音乐",
        preferred_language: "偏好语言",
        english: "英文",
        chinese: "中文",
        consent_for_receiving_email: "不同意接收电子邮件",
        yes: "是",
        no: "否",
        upgrade_membership: "升级会籍",
        renew: "续会",
        membership_card: "显示电子会员卡",
        complete_profile_rewards: '填妥个人资料，即享b500迎新优惠（相当于<span class="asiamile-logo">a</span>125里数！）',
        consent_setting: "同意设定",
        receive_email_promotion_setting: "接收电子邮件促销设置",
        email_or_mobile: "电邮/电话",
        back_to_member_detail: '回到我的帐户',
        missing_membership_card_hint: '未找到会员卡，请联系我们的客服。',
        complete_profile_to_upgrade: '填妥个人资料，即时升级会籍',
        email_or_mobile_placeholder: "请参阅启用帐户信件或电邮填写",
        full: "已达成",
        manage_membership: "管理会籍",

        // YA form
        guardian_name: "[SC] Guardian's Name",
        guardian_mobile: "[SC] Guardian's Mobile",
        guardian_email: "[SC] Guardian's Email",
        school_name: "[SC] School Name",
        school_type: "[SC] School Type",
        student_card_file: "[SC] Student ID",

        // Member detail page
        sc_upgrade_offer: "渣打国泰万事达卡持卡人",
        sc_upgrade_offer_btn_upgrade_now: "免費升級",
        sc_upgrade_offer_btn_details: "詳細資料",
        sc_upgrade_offer_btn_tnc: "條款和細則",
      },
      change_password: {
        change_of_password: "更改密码",
        basic_information: "基本资料",
        please_enter_a_new_password_for_your_club_bravo_account: "请为您的港乐会帐户设定新密码。",
        your_new_password: "新密码",
        confirm_your_new_password: "确认新密码",
      },
      point_transaction_log: {
        point_transaction_log: "积分志账",
        view_point_transaction_log: "查看积分志账",
        date: "日期",
        description: "项目",
        point_change: "积分变更",
        balance: "积分结余",
        date_format: "Date format",
        transaction_types: "Transaction Types ",
        profile_completion: "填妥个人资料",
        ticket_scanned: "扫描门票",
        ticket_scanned_with_bonus: "扫描门票（连奖励积分）",
        reward_redemption: "兑换奖赏",
        asia_miles_redemption: "兑换「亚洲万里通」里数",
        bulk_import: "系统扫描门票 (23/24 乐季预订)",
        return_refund_exchange: "扣除积分 (退票/退款/换票)",
        christmas_birthday_reward_2023: "2023 圣诞赏「乐」双倍积分",
        birthday_double_points: "生日双倍分数",
        season_subscription_points: "2324乐季预订积分"
      },
      scan_tutorial: {
          start_now: "马上开始",
          tips_and_steps: "提示和步骤",
      },
      scan_your_ticket: {
        ref_number: "参考编号：",
        concert_name: "音乐会名称：",
        performance_date: "演出日期：",
        performance_time: "演出时间：",
        seat_number: "座位编号：",
        price: "票价：",
        success_cb_point_reward: "赚取之港乐会积分:",
        fail_ticket_submission_will_not_be_counted_message: "是次门票登记将不能赚取港乐会积分。请检查门票并将正确门票重新上载。",
        enjoy_the_concert: "期待下次在音乐会见到您！",
        scanner_hint: "请在方框内扫描",
        upload_photo: "上传照片",
        start_scanning: "开始扫描",
        stop_scanning: "停止扫描",
        upload_photo_hint: "请扫描或上传您的门票",
        upload_photo_reminder: "无法扫描门票? 试试上传照片!",
        invalid_qrcode: "二维码无效",
        scan_another: '扫描另一张票',
        ticket: '门票',
        tnc: '条款与细则',
        login_before_scan: '扫描前请先登入',
        scan_hint: '请确保您的电子邮件已通过验证',
        pending_and_invalid: '如欲提交门票资料，请点击「确定」以便继续进行认证。如欲提交另一场门票资料，请点击「取消」以重新提交。'
      },
      offer_convert_to_miles: {
        points_to_convert: "兑换至里数之港乐会积分",
        asia_miles_intro: '以港乐会积分轻松兑换「亚洲万里通」里数，每<span class="cb-logo">b</span>{{ rate }} 就可兑换<span class="asiamile-logo">a</span>1里数！',
        conversion_rate: '兑换率：<span class="cb-logo">b</span>{{ rate }} = <span class="asiamile-logo">a</span>1里',
        suggest_points_to_convert: "建议兑换至里数之港乐会积分",
        asia_miles_to_be_converted: "兑换里数：",
        miles: "里",
        points: "分",
        available_point_before_conversion: "兑换前之港乐会积分结余：",
        available_point_after_conversion: "兑换后之港乐会积分结余：",
        your_asia_miles_member_first_name: "您的国泰会员名称名字：",
        your_asia_miles_member_last_name: "您的国泰会员名称姓氏：",
        your_asia_miles_membership_number: "您的国泰会员号码：",
        confirm_asia_miles_account_correct: "我确认我的国泰会员名称及号码正确",
        understand_the_conversion_is_non_changeable_and_non_reversible: "我明白港乐会积分兑换至「亚洲万里通」里数之交易申请不可更改或逆转",
        i_agree_to_the: "我接受",
        term_and_condition: "条款及细则",
        redeem_now: "立即兑换",
        come_back_with_more_cb_points: "累积更多港乐会积分以兑换！",
        redeem__confirm_checkbox: "您确认兑换吗？",
        sold_out: "售罄",
        back_to_offers: "浏览其他奖赏",
        asia_miles_convert_ref_no: "参考编号：",
        asia_miles_name: "亚洲万里通账户名称：",
        asia_miles_no: "亚洲万里通账户号码：",
        asia_miles_converted: "转换的亚洲万里通里数：",
        point_to_convert: "转换中使用的港乐会积分：",
        remaining_points: "转换后可用的港乐会积分：",
      },
      month: {
        1: '一月',
        2: '二月',
        3: '三月',
        4: '四月',
        5: '五月',
        6: '六月',
        7: '七月',
        8: '八月',
        9: '九月',
        10: '十月',
        11: '十一月',
        12: '十二月',
      },
      country: {
        hong_kong_sar: '香港',
        taiwan: '台湾',
        china: '中国',
      },
      index: "首頁",
      button: {
        close: '关闭',
        submit: '提交',
        save: '储存',
        confirm: '确定',
        retake: '取消',
        login: '登入',
        logout: '登出',
        back: '返回',
        edit: '编辑',
        verify_later: '稍后验证',
        start_now: "马上开始",
        go_to_profile: "前往个人资料"
      },
      error: {
        validation_error: '验证错误',
        invalid_point_conversion: '如要转换积分成里数，该港乐会积分数值需可被 4 整除。请重新输入港乐会积分，谢谢！',
        required: '请填写{{field}}',
        invalid: '无效{{field}}',
        missing: '未填写{{field}}',
        already_been_used: '该{{field}}已被使用',
        min_points_not_reached: '如要转换积分成里数，需要至少转换 1000 港乐会积分。请重新输入港乐会积分，谢谢！',
        missing_tnc: '请阅读条款和条件并选中以下复选框',
        confirmedEmailIsCorrect: '请确认电子邮件正确并选中以下复选框',
        invalid_asia_miles: '无效的「亚洲万里通」兑换',
        incorrect_account_data: '连结帐户的资料错误',
        profile_migrate_failure: '保存Profile Is Migrated失败!',
        not_enough_points: '您尚未有充足的港乐会积分兑换此奖赏',
        invalid_tier: '您现时的帐户为金卡港乐会会籍，故未能兑换「港乐会红卡会籍」，请检查您所登入的帐户是否正确。如有任何疑问，欢迎电邮至bravo@hkphil.org查询。',
        duplicate_token: '此港乐会会籍已被兑换。请前往「国泰购物」 以兑换另一个港乐会会籍。谢谢！',
      },
      scanner: {
        verify_email: '请验证邮箱',
      },
      verify_email: {
        title: '验证您的电子邮件',
        content: '验证邮件已发送。 请在注册前检查您的邮箱以验证电子邮件。',
        resend: '重新发送验证邮件',
        reload: '重新加载',
        reload_hint: '如果您已经验证了您的电子邮件，请重新加载网页',
        popup_title: '验证电邮已重新发出',
        popup_message: '请检查您的电子邮箱，并按指示确认电邮地址。',
      },
      model_constants: {
        MemberProfile: {
          SCHOOL_TYPES: {
            kindergarten: '[SC] Kindergarten',
            primary_school: '[SC] Primary School',
            secondary_school: '[SC] Secondary School',
            post_secondary: '[SC] Post-secondary',
          },
        },
      },
    },
  },
};

export default resources;
